export const japaneseTranslate = {
    "dashboard": "ダッシュボード",
    "sensors": "センサー",
    "users": "ユーザー",
    "customers": "顧客",
    "reports": "報告書",
    "total": "合計",
    "settings": "設定",
    "all": "すべて",
    "sensor id": "センサーID",
    "customer": "お客様",
    "site": "サイト",
    "site details": "サイト詳細",
    "zone": "ゾーン",
    "zone details": "ゾーンの詳細",
    "close": "閉まっている",
    "scanner": "スキャナー",
    "scan": "スキャン",
    "sensor type": "センサータイプ",
    "add sensor": "センサーを追加",
    "sensor name": "センサー名",
    "zone map": "ゾーンマップ",
    "add user": "ユーザーを追加する",
    "all users": "すべてのユーザー",
    "superadmin": "スーパー管理者",
    "regional manager": "地域担当マネージャー",
    "branch manager": "支店長",
    "service technician": "サービス技術者",
    "custom users": "カスタムユーザー",
    "username": "ユーザー名",
    "email": "Eメール",
    "first name": "名",
    "last name": "姓",
    "role": "役割",
    "actions": "行動",
    "my profile": "私のプロフィール",
    "edit user": "ユーザーを編集",
    "update": "更新",
    "please enter your username": "ユーザー名を入力してください",
    "please enter site name": "サイト名を入力してください",
    "please enter your email": "あなたのメールアドレスを入力してください",
    "please enter valid email": "有効なメールIDを入力してください",
    "please enter your password": "パスワードを入力してください",
    "please confirm your password": "パスワードを確認してください",
    "passwords do not match": "パスワードが一致していません",
    "please enter your first name": "名を入力してください",
    "please enter your last name": "あなたの姓を入力",
    "please select language": "言語を選択してください",
    "please enter your timezone": "タイムゾーンを入力してください",
    "please enter your phone no.": "電話番号を入力してください",
    "please enter valid phone no.": "有効な電話番号を入力してください",
    "please enter your city": "あなたの市町村を入力してください",
    "please enter your state": "都道府県を入力してください",
    "please enter your country": "国を入力してください",
    "please enter your address": "住所を入力してください",
    "please enter your zip code\/postal code": "郵便番号を入力してください",
    "role name": "ロール名",
    "last updated at": "最終更新日",
    "create customer": "顧客を作成する",
    "resources": "リソース",
    "sites": "サイト",
    "zones": "ゾーン",
    "address": "住所",
    "country": "国",
    "state": "状態",
    "city": "シティ",
    "zip code\/postal code": "郵便番号\/郵便番号",
    "contact person": "接触",
    "upload photo": "写真をアップする",
    "details": "詳細",
    "user details": "ユーザーの詳細",
    "enter user details": "ユーザーの詳細を入力",
    "offline": "オフライン",
    "rodent": "齧歯類",
    "glue board": "グルー ボード",
    "flying insect": "飛翔昆虫",
    "fly": "飛翔昆虫",
    "Crawling Insect": "這う昆虫",
    "crawling insect": "這う昆虫",
    "add site": "サイトを追加",
    "site name": "サイト名",
    "select site type": "サイトタイプを選択",
    "air quality": "空気",
    "zone list": "ゾーン",
    "zone name": "ゾーン名",
    "select site": "サイトを選択",
    "edit profile": "編集",
    "edit theme": "テーマ",
    "change password": "パスワードを変更する",
    "new password": "古い",
    "confirm new password": "新着",
    "sign out": "ログ",
    "sign in": "ログイン",
    "enter username": "ユーザーネームを入力してください",
    "enter password": "パスワード",
    "password": "パスワード",
    "remember me": "覚えている",
    "forgot password?": "忘れた",
    "download csv file": "CSVファイルをダウンロード",
    "download pdf file": "PDFファイルをダウンロード",
    "download png file": "PNGファイルをダウンロード",
    "pest infestation": "害虫の侵入",
    "notification": "通知",
    "timeseries": "時系列",
    "add action": "アクションを追加",
    "online": "オンライン",
    "select customer": "顧客を選択",
    "select zone": "ゾーンを選択",
    "please choose a excel file": "Excelファイルを選択してください",
    "ok": "わかった",
    "clear": "晴れ",
    "delete": "削除する",
    "re-assign sensor": "センサーの再割り当て",
    "edit sensor": "センサーを編集",
    "update sensor": "センサーを更新",
    "sensor details": "センサーの詳細",
    "sensor unavailable": "センサーを利用できません",
    "sensor id unavailable": "センサーIDを使用できません",
    "sensor installed": "センサー搭載",
    "re assign sensor": "センサーの再割り当て",
    "re assigned successfully": "正常に再割り当てされました",
    "directional chart": "方向チャート",
    "no sensor available": "利用可能なセンサーがありません",
    "to add a sensor.": "センサーを追加します。",
    "select pest type": "害虫の種類を選択",
    "customer-site time series comparison": "顧客サイトの時系列比較",
    "hour": "時",
    "week": "週",
    "month": "月",
    "year": "年",
    "notifications": "通知",
    "select sensor": "センサーを選択",
    "enable": "有効にする",
    "disable": "無効にする",
    "user successfully created.": "ユーザーが正常に作成されました。",
    "user successfully updated.": "ユーザーが正常に更新されました。",
    "customer successfully created.": "顧客が正常に作成されました。",
    "show customer list": "顧客リストを表示",
    "individual customer": "個人のお客様",
    "site successfully created.": "サイトが正常に作成されました。",
    "show site list": "サイトリストを表示",
    "show zone list": "ゾーンリストを表示",
    "choose  a activity category": "アクティビティカテゴリを選択してください",
    "processing steps": "処理手順",
    "assigned successfully": "正常に割り当てられました",
    "yes": "はい",
    "no": "いいえ",
    "updated successfully": "更新成功",
    "please choose a image file": "画像ファイルを選択してください",
    "no user available": "利用可能なユーザーはありません",
    "click here": "ここをクリック",
    "Clone Role": "コピー作成ロール",
    "edit customer": "顧客を編集",
    "edit site": "サイトを編集",
    "day": "日",
    "today": "今日",
    "deleted successfully": "正常に削除されました",
    "created successfully": "うまく作成されました",
    "edited successfully": "正常に編集されました",
    "password changed successfully.": "パスワードは正常に変更されました。",
    "site successfully updated.": "サイトが正常に更新されました。",
    "customer successfully updated.": "顧客は正常に更新されました。",
    "select all": "すべて選択",
    "collapse": "崩壊",
    "expand": "展開する",
    "select language": "言語を選択する",
    "direction": "方向",
    "sensor": "センサー",
    "hour of the day": "一日の時間",
    "select timezone": "タイムゾーンを選択",
    "roles": "役割",
    "total count": "総数",
    "from": "から",
    "to": "に",
    "no.": "いや",
    "stop scan": "スキャンを停止",
    "aqi": "AQI",
    "at": "で",
    "edit role": "役割を編集",
    "updated by": "更新者",
    "updated at": "更新場所",
    "created by": "作成者",
    "successfully sensor installed": "センサーが正常にインストールされました",
    "sensor updated successfully": "センサーは正常に更新されました",
    "custom role created successfully": "特別な役割が正常に作成されました",
    "custom role edited successfully": "特別な役割が正常に編集されました",
    "Sensor already scanned": "センサーはすでにスキャンされています",
    "file formats should be JPG\/JPEG\/PNG": "ファイル形式は JPG/JPEG/PNG である必要があります",
    "sensor(s) in the list were added successfully": "リストにあるセンサーは正常に追加されました",
    "enter your pincode": "PINコードを入力",
    "enter your country": "国を入力",
    "enter your state": "都道府県を入力",
    "enter your city": "あなたの市町村を入力入る",
    "enter your street": "通りを入力",
    "please select your timezone": "タイムゾーンを選択してください",
    "please enter zone name": "ゾーン名を入力してください",
    "please select role": "役割を選択してください",
    "please enter your customer name": "お客様名を入力してください",
    "please enter your contact person": "担当者を入力してください",
    "pest type": "害虫の種類",
    "last updated": "最終更新",
    "please enter your current password": "現在のパスワードを入力してください",
    "Passwords must be between 6 and 12 characters and contain at least two of the following: uppercase letters, lowercase letters, numbers, and symbols.": "パスワードは 6 ～ 12 文字で、大文字、小文字、数字、および記号のうち少なくとも 2 つが含まれている必要があります。",
    "please select customer (optional)": "顧客を選択してください（オプション",
    "sensor error": "センサーエラー",
    "please choose customer": "顧客を選択してください",
    "please choose site": "サイトを選択してください",
    "please choose zone": "ゾーンを選択してください",
    "please enter sensor name": "センサー名を入力してください",
    "please choose sensor type": "センサータイプを選択してください",
    "no users available.": "利用可能なユーザーがいません。",
    "rodent sensor": "齧歯類センサー",
    "please enter sensor id": "センサーIDを入力してください",
    "sensor already installed": "センサーはすでにインストールされています",
    "deployment type": "展開タイプ",
    "max length 200": "最大長200",
    "max length 20": "最大長20",
    "max length 8": "最大長8",
    "sensor name must be a minimum of 6 characters": "センサー名は6文字以上にする必要があります",
    //  "sensor name cannot be blank": "センサー名は空白にできません",
    "cancel": "キャンセル",
    "qr code scan": "QRコードスキャン",
    "file size should be less than 5 MB": "ファイルサイズは5 MB未満である必要があります",
    "successfully uninstalled the sensor": "センサーを正常にアンインストールしました",
    "successfully reassigned to selected customer": "選択した顧客に正常に再割り当てされました",
    "complete installation": "完全なインストール",
    "This Sensor is not currently active on the IoP system and cannot be used.": "このセンサーは現在IoPシステムでアクティブではなく、使用できません",
    "Invalid sensor ID! This sensor is already assigned elsewhere.": "無効なセンサーID！このセンサーはすでに別の場所に割り当てられています。",
    "This sensor is already installed on your account.": "このセンサーは既にアカウントにインストールされています。",
    "This sensor is already added to your account.": "このセンサーはすでにアカウントに追加されています。",
    "This sensor is has already been installed on the IoP system.": "このセンサーはすでにIoPシステムにインストールされています。",
    "Invalid sensor ID specified! Please enter a valid sensor ID for installation.": "センサーIDが無効です!インストールする有効なセンサー ID を入力してください。",
    "error during get sensor metadata details : sensorId not available": "センサーメタデータの詳細の取得中にエラーが発生しました：sensorIdを使用できません",
    "no whitespace at the beginning and end": "先頭と末尾に空白がない",
    "date & time": "日付時刻",
    "manage roles": "役割を管理する",
    "reset": "リセット",
    "choose a reporting manager": "上司を選択する",
    "organisation": "組織",
    "create new custom role": "新しい特別な役割を作成する",
    "edit custom role": "特別な役割を編集",
    "parent role": "親の役割",
    "enter new custom role name": "新しい特別な役割名を入力",
    "please enter custom role name": "特別な役割名を入力してください",
    "please enter different the custom role name": "別の特別な役割名を入力してください",
    "enter the role description": "役割の説明を入力してください",
    "no role available": "使用可能な役割はありません",
    "permission": "許可",
    "create": "つくる",
    "view": "見る",
    "please enter a valid mobile number": "有効な電話番号を入力して下さい",
    "clone": "クローン",
    "description": "説明",
    "delete role": "役割を削除する",
    "max length 225": "最大長225",
    "re-enter Password": "パスワードを再入力してください",
    "please enter re-enter password": "パスワードを再入力してください",
    "name": "名前",
    "reporting manager": "上司",
    "update user": "ユーザーの更新",
    "timezone": "タイムゾーン",
    "click to enable": "クリックして有効にする",
    "click to disable": "クリックして無効にします",
    "account overview": "口座[アカウント]状況",
    "reporting users": "ユーザーの報告",
    "detections": "検出",
    "sensors overview": "センサーの概要",
    "view all": "すべて表示",
    "pest detections": "害虫の検出",
    "all sensors": "すべてのセンサー",
    "new": "新着",
    "active": "アクティブ",
    "idle": "アイドル",
    "home": "ホーム",
    "glue board details": "グルー ボードの詳細",
    "added by": "追加者",
    "added on": "追加日",
    "location description": "位置\/説明",
    "sensor pictures": "センサー写真",
    "glue board id": "グルーボードID",
    "24 hrs": "24時間",
    "12 hrs": "12時間",
    "6 hrs": "6時間",
    "1 hr": "1時間",
    "1 week": "1週間",
    "1 month": "1ヶ月",
    "lifetime": "一生",
    "remove sensor": "センサーを取り外します",
    "What is the reason for removing this sensor?": "このセンサーを削除する理由は何ですか?",
    "sensor damaged": "センサーが破損している",
    "other reason": "他の理由",
    "Yes, I confirm this sensor to be permanently removed": "はい、このセンサーを永久に削除することを確認します",
    "Enter Sensor ID or Scan QR code": "センサーIDを入力するかQRコードをスキャンします",
    "Enter Sensor name": "センサー名を入力してください",
    "select deployment type": "導入タイプの選択",
    "enter sensor location description": "センサーの場所の説明を入力してください",
    "sensor location description": "センサーの場所の説明",
    "Describe the placement of the sensor in words.": "センサーの配置を言葉で説明してください。",
    "add sensors": "センサーを追加する",
    "basic details": "基本的な詳細",
    "select sensors": "センサーを選択",
    "review & complete": "レビューと完了",
    "number of sensors to be added": "追加するセンサーの数",
    "please choose number of sensors": "センサーの数を選択してください",
    "back": "バック",
    "save & continue": "保存して続行",
    "I confirm above sensors to be added to my account.": "上記のセンサーがアカウントに追加されたことを確認します。",
    "max length 255": "最大長255",
    "please choose pest type": "害虫の種類を選択してください",
    "last seen": "最後に見たのは",
    "Unavailable": "利用できません",
    "comm status": "状態",
    "Comm Status": "状態",
    "sensor images": "センサー画像",
    "proceed": "続行します",
    "are you sure want to cancel it ?": "キャンセルしてもよろしいですか？",
    "weeks": "週",
    "days": "日々",
    "hours": "時間",
    "customer information": "顧客情報",
    "Enter Customer Name": "顧客名を入力してください",
    "contact person details": "担当者の詳細",
    "I confirm to add this new customer to my account": "この新しい顧客が私のアカウントに追加されたことを確認します",
    "Update Avatar": "アバターを更新する",
    "Enter Email ID": "メールIDを入力してください",
    "enter phone number": "電話番号を入力してください",
    "enter contact person name": "担当者名を入力してください",
    "all customers": "すべてのお客様",
    "all sites": "すべてのサイト",
    "all zones": "すべてのゾーン",
    "customer, site, zone": "顧客、サイト、ゾーン",
    "manage sites": "サイトの管理",
    "postal code": "郵便番号",
    "phone": "電話",
    "enter address": "住所を入力してください",
    "phone number": "電話番号",
    "email id": "電子メールID",
    "customer address": "お客様の住所",
    "Manage Sites and Zones": "サイトとゾーンを管理する",
    "contact person name": "担当者名",
    "I confirm to add this new customer to my account, and to all the reporting managers above me.": "この新しい顧客を自分のアカウントと、私の上のすべての上司に追加することを確認します。",
    "This role name is already taken. Please try a different one!": "この役割名はすでに使用されています。別のものを試してみてください！",
    "I confirm to add the above sensors to my account, and to all the managers of this site.": "上記のセンサーを自分のアカウントとこのサイトのすべての管理者に追加することを確認します。",
    "please select the checkbox": "チェックボックスを選択してください",
    "remove customer": "顧客を削除する",
    "customer removed successfully": "お客様が正常に削除されました",
    "please enter valid sensor id": "有効なセンサー識別子を入力してください",
    "This site name is already taken. Please try a different one!": "このサイト名はすでに使用されています。別のものを試してみてください！",
    "This zone name is already taken. Please try a different one!": "このゾーン名はすでに使用されています。別のものを試してみてください！",
    "This sensor name is already taken. Please try a different one!": "このセンサー名はすでに使用されています。別のものを試してみてください！",
    "sensor name already added": "センサー名はすでに追加されています",
    "24 Hours": "24時間",
    "This customer name is already taken. Please try a different one!": "この顧客名はすでに使用されています。別のものを試してみてください！",
    "resolved": "解決済み",
    "resolve": "解決する",
    "Recent Detection": "最近の検出",
    "detection log": "検出ログ",
    "time stamp": "タイムスタンプ",
    "detection details": "検出の詳細",
    "new detection": "新しい検出",
    "new detections": "新しい検出",
    "no detections": "検出なし",
    "Proof of Detection Resolution": "検出分解能の証明",
    "I have completed the pest management and sensor services required to resolve this detection.": "この検出を解決するために必要な害虫管理とセンサーサービスを完了しました。",
    "Did you find any evidence of pest presence?": "害虫が存在する証拠は見つかりましたか?",
    "Successfully resolved activity": "正常に解決されたアクティビティ",
    "Resolution Information": "解決情報",
    "Resolution Number": "決議番号",
    "resolved by": "解決者",
    "resolved on": "解決済み",
    "Date": "日付",
    "Time": "時間",
    "view location": "場所を表示",
    "Detection has been successfully resolved.": "検出は正常に解決されました。",
    "sensor is malfunctioning": "センサーが誤動作している",
    "sensor is no longer required": "センサーは不要になりました",
    "This sensor will be marked as malfunctioning and removed permanently. The removed sensor cannot be reused.": "このセンサーは故障としてマークされ、永久に削除されます。取り外したセンサーは再使用できません。",
    "This sensor will be removed and can be later added back as required.": "このセンサーは削除され、必要に応じて後で追加し直すことができます。",
    "I confirm the permanent removal of this sensor.": "このセンサーの永久的な取り外しを確認します。",
    "I confirm the removal of this sensor.": "このセンサーの取り外しを確認します。",
    "The sensor has been permanently removed": "センサーは完全に取り外されました",
    "The sensor has been successfully removed": "センサーの取り外しに成功しました",
    "date range": "日付範囲",
    "no Glue board details": "グルー ボードの詳細なし",
    "replace sensor": "センサーを交換してください",
    "basic information": "基本情報",
    "sensor replacement": "センサーの交換",
    "Glue board detection breach limit": "グルー ボード検出が限度を超えている",
    "Glue board duration breach limit": "グルー ボードの持続時間が限度を超えている",
    "Communication status": "通信状態",
    "pictures": "ピクチャー",
    "What is the reason for replacing this sensor?": "このセンサーを交換する理由は何ですか?",
    "Sensor malfunctioning": "センサーの誤動作",
    "Sensor Damaged": "センサーが損傷している",
    "Sensor Lost": "センサーが失われました",
    "Technician can give the statement of the existing sensor condition.": "技術者は、既存のセンサーの状態を説明できます。",
    "Please describe below": "以下に説明してください",
    "confirm & replace": "確認して交換する",
    "enter the new sensor details": "新しいセンサーの詳細を入力します",
    "I confirm the addition of the new sensor in place of the sensor selected for replacement. This action cannot be undone.": "交換対象として選択したセンサーの代わりに新しいセンサーが追加されることを確認します。この操作は元に戻すことができません。",
    "The sensor has been replaced successfully!": "センサーは正常に交換されました！",
    "View Sensor list": "センサーリストを見る",
    "breach limits": "違反制限",
    "Default No. of days for the glueboard expiry": "グルーボードの有効期限のデフォルトの日数",
    "Default max fly detections allowed on the glueboard": "グルー ボード上で許可されるデフォルトの飛翔昆虫最大検出数",
    "please enter No. of days for the glueboard expiry": "グルーボードの有効期限の日数を入力してください",
    "please enter max flying insect detections allowed on the glue board": "グルー ボード上で許可される飛翔昆虫最大検出数を入力してください",
    "Default fly detections limit": "デフォルトの飛翔昆虫検出制限",
    "Default Rodent detections limit": "デフォルトの齧歯動物の検出制限",
    "Please Enter Flying Insect Critical Control Limit": "飛翔昆虫の重要管理限界値を入力してください",
    "Please Enter Crawling Insect Critical Control Limit": "這う昆虫の重要管理限界を入力してください",
    "Please Enter Rodent Critical Control Limit": "齧歯類重要管理限度を入力してください",
    "language": "言語",
    "No. of days for the glueboard expiry": "グルーボードの有効期限の日数",
    "max fly detections allowed on the glueboard": "グルー ボード上で許可される最大飛翔昆虫検出数",
    "Default No. of days for the glue board expiry between 1 to 90": "グルーボードの有効期限のデフォルトの日数は 1 ～ 90 です。",
    "Default max flying insect detections allowed on the glue board between 1 to 1999": "1〜1999のグルーボードで許可されるデフォルトの最大フライ検出",
    "Default flying insect detections limit between 0 to 1999": "デフォルトのフライ検出の制限は0〜1999です。",
    "Default Crawling Insect detections limit between 0 to 1999": "デフォルトのクロール昆虫検出数は 0 から 1999 の間で制限されます",
    "Default Rodent detections limit between 0 to 1999": "デフォルトの齧歯動物の検出制限は 0 ～ 1999 です",
    "This sensor will be marked as malfunctioning and removed permanently. The replaced sensor cannot be reused.": "このセンサーは故障としてマークされ、永久に削除されます。交換したセンサーは再使用できません。",
    "max fly detections allowed on the glueboard between 1 to 1999": "1 年から 1999 年までの間、グルー ボード上で許可された飛翔昆虫の最大検出数",
    "No. of days for the glue board expiry between 1 to 90": "グルーボードの有効期限日数（1～90）",
    "new sensors": "新しいセンサー",
    "active sensors": "アクティブセンサー",
    "idle sensors": "アイドルセンサー",
    "offline sensors": "オフラインセンサー",
    "breach": "違反",
    "to create a customer": "顧客を作成するには",
    "Replacing sensor should be of the same pest type.": "交換センサーは同じ害虫タイプでなければなりません。",
    "site type": "サイトタイプ",
    "No customer available.": "利用可能な顧客はありません。",
    "no site available": "利用可能なサイトはありません",
    "site event log": "サイトイベントログ",
    "add site & zone": "サイトとゾーンを追加",
    "add more": "さらに追加",
    "enter state": "状態に入る",
    "Enter zip code\/postal code": "郵便番号を入力",
    "enter site name": "サイト名を入力",
    "enter city": "市に入る",
    "enter country": "国を入力してください",
    "service team": "サービスチーム",
    "user name": "ユーザー名",
    "I confirm the addition of this new site to my account, service team and all the reporting managers.": "私は、この新しいサイトを私のアカウント、サービス チーム、およびすべての上司に追加することを確認します。",
    "please select timezone": "タイムゾーンを選択してください",
    "site information": "サイト情報",
    "edit site & zone": "サイトとゾーンを編集",
    "Flying Insect Glue Board Settings": "飛翔昆虫グルー ボードの設定",
    "Critical Control Limits": "重要な管理限界",
    "Default Flying Insect Critical Control Limit of the Zone": "ゾーンのデフォルトの飛翔昆虫重大管理限界",
    "Default Crawling Insect Critical Control Limit of the Zone": "ゾーンのデフォルトの這う昆虫の重要管理限界",
    "Default Rodent Critical Control Limit of the Zone": "ゾーンのデフォルトの齧歯類の重要な管理限界",
    "resolved detections": "解決された検出",
    "is Breached on": "に違反しています",
    "is Breached at": "で違反されています",
    "Flying Insect Glue Board Detections": "飛来昆虫グルー ボードの検出数",
    "Zone Rodent Critical Control Limit": "ゾーン齧歯動物の重要な管理限界",
    "Zone Fly Critical Control Limit": "ゾーン飛翔昆虫の重要管理限界",
    "Zone Crawling Insect Critical Control Limit": "ゾーンゴキブリの重要な管理限界",
    "manage zones": "ゾーンの管理",
    "breach limit": "違反制限",
    "click": "クリック",
    "This service team member is already taken. Please try a different one!": "このサービス チーム メンバーはすでに割り当てられています。他のメンバーを選択してください!",
    "add team member": "チームメンバーを追加する",
    "no service team available": "利用可能なサービスチームはありません",
    "to add team member": "チームメンバーを追加するには",
    "revoke access": "アクセス権を取り消す",
    "updated on": "に更新",
    "add member": "メンバーを追加",
    "number of sites assigned": "割り当てられたサイトの数",
    "select user": "ユーザーを選択",
    "please select user": "ユーザーを選択してください",
    "zone detection resolution": "ゾーン検出の解像度",
    "action": "アクション",
    "site detection resolution": "サイト検出の解決",
    "I have completed the pest management and sensor services required to resolve the new detections in this site.": "このサイトでの新しい検出を解決するために必要な害虫管理とセンサーサービスを完了しました。",
    "Sensor Detection Resolution": "センサー検出解像度",
    "Grant Access": "アクセス許可",
    "Add team member to the site": "チームメンバーをサイトに追加する",
    "I confirm granting site access to this member and to all the reporting managers.": "このメンバーとすべての上司にサイトへのアクセスを許可することを確認します。",
    "has been granted access to the selected site": "選択したサイトへのアクセスが許可されました",
    "Remove team member from the site": "サイトからチームメンバーを削除する",
    "I confirm revoking site access from this member.": "このメンバーからのサイトへのアクセスを取り消すことを確認します。",
    "has been revoked access to the selected site": "選択したサイトへのアクセスが取り消されました",
    "detected": "検出されました",
    "notifications center": "通知センター",
    "Edit Service Team Members for the site by changing users below": "以下のユーザーを変更して、サイトのサービスチームメンバーを編集します",
    "Add Service Team Members for the site by choosing users below": "以下のユーザーを選択して、サイトのサービスチームメンバーを追加します",
    "Please use a different username": "別のユーザー名を使用してください",
    "is": "です",
    "edit": "編集",
    "replace": "交換してください",
    "remove": "削除する",
    "Zone Information": "ゾーン情報",
    "User": "ユーザー",
    "important notifications": "重要なお知らせ",
    "confirmation": "確認",
    "Are you sure you want to mark all the new notifications as read?": "すべての新しい通知を既読としてマークしてもよろしいですか？",
    "Yes, Proceed": "はい、続行します",
    "The new notifications have been successfully cleared. They can still be accessed from the Notifications Center.": "新しい通知は正常に消去されました。通知センターからは引き続きアクセスできます。",
    "Site Detections Resolved by": "サイトの検出が解決されました",
    "Zone Detections Resolved by": "によって解決されたゾーン検出",
    "New Flying Insect Detections at Site": "現場で新たな飛翔昆虫を検出",
    "New Crawling Insect Detections at Site": "サイトでの新しい這う昆虫の検出",
    "New Rodent Detections at Site": "サイトでの新しい齧歯動物の検出",
    "Flying Insect Sensors": "飛翔昆虫センサー",
    "Rodent Sensors": "げっ歯類センサー",
    "zone detections resolved": "ゾーン検出が解決されました",
    "site detections resolved": "サイトの検出が解決されました",
    "by": "沿って",
    "is Breached!": "違反しています！",
    "Breached!": "違反しました！",
    "All Notifications": "すべての通知",
    "Breach Alerts": "違反アラート",
    "Site Events": "サイトイベント",
    "Sensor Events": "センサーイベント",
    "Mark all as read": "すべて既読としてマーク",
    "I have completed the pest management and sensor services required to resolve the new detections in this zone.": "このゾーンでの新しい検出を解決するために必要な害虫管理とセンサーサービスを完了しました。",
    "total sensors": "トータルセンサー",
    "IoP® Dashboard": "IoP®ダッシュボード",
    "Flying Insect Detections": "飛翔昆虫の探知",
    "Rodent Detections": "齧歯類の検出",
    "Fly Detection": "飛翔昆虫探知",
    "Rodent Detection": "齧歯類の検出",
    "Fly Trend": "飛翔昆虫の傾向",
    "Rodent Trend": "齧歯類の傾向",
    "New Detections by Pest Type": "害虫の種類による新しい検出",
    "Detections - New vs Resolved": "検出 - 新規対解決済み",
    "View All New Detections": "すべての新しい検出を表示",
    "Sensors grouped by Pest Type": "害虫の種類ごとにグループ化されたセンサー",
    "View All Sensors": "すべてのセンサーを表示",
    "Sensors grouped by Communication Status": "通信ステータスごとにグループ化されたセンサー",
    "© 2024 Evoscien. All Rights Reserved.": "© 2024 Evoscien。全著作権所有。",
    "There are": "がある",
    "more new notifications,": "より多くの新しい通知、",
    "detections overview": "検出の概要",
    "Fly Sensor Detections Resolved by": "飛翔昆虫センサー検出数解決者",
    "Fly Sensor Detections Resolved": "飛翔昆虫センサー検出結果",
    "New Fly Detections": "新しい飛翔昆虫の検出",
    "New Rodent Detections": "新しい齧歯動物の検出",
    "create site": "サイトを作成する",
    "to create a site.": "サイトを作成します。",
    "Add Zone Map": "ゾーンマップの追加",
    "please enter the role description": "役割の説明を入力してください",
    "Sensor Detections Resolved by": "によって解決されたセンサー検出",
    "sensor detections resolved": "センサー検出が解決されました",
    "current password": "現在のパスワード",
    "reset password": "パスワードを再設定する",
    "Additional Comments": "追加コメント",
    "Do you want to resolve the detections on this site?": "このサイトでの検出を解決しますか？",
    "Do you want to resolve the detections on this sensor?": "このセンサーの検出を解決しますか？",
    "Do you want to resolve the detections on this zone?": "このゾーンでの検出を解決しますか？",
    "Resolve": "解決する",
    "Cancel": "キャンセル",
    "pest infestation summary": "害虫の蔓延の概要",
    "time series comparison": "時系列比較",
    "Time Series": "時系列",
    "Cumulative Sum": "累積合計",
    "Hours Distribution": "時間配分",
    "Percentage": "パーセンテージ",
    "Trend is unavailable for the current selection criteria.": "現在の選択基準ではトレンドは利用できません。",
    "Data Aggregation By": "によるデータ集約",
    "Detections Report": "検出レポート",
    "- Create Customer -": "顧客を作成する",
    "Critical Control Limit": "重要な管理限界",
    "max length 12": "最大長さ12",
    "create user": "ユーザーを作成",
    "to create a user.": "ユーザーを作成します。",
    "pest detections report": "害虫検出レポート",
    "all crawling insect": "すべてのクロール昆虫",
    "all rodents": "すべての齧歯動物",
    "all crawling insect sensors": "すべての這う昆虫センサー",
    "all rodent sensors": "すべての齧歯類センサー",
    "all fly sensors": "すべての飛翔昆虫センサー",
    "Crawling Insect Critical Control Limit": "這う昆虫の重要管理限界",
    "Fly Critical Control Limit": "フライクリティカルコントロールリミット",
    "Rodent Critical Control Limit": "齧歯類の重要な管理限界",
    "max length 24": "最大長さ24",
    "min length 6": "最小長さ6",
    "year I": "1年目",
    "year II": "2年目",
    "week I": "1週目",
    "week II": "2週目",
    "month I": "1か月目",
    "month II": "2か月目",
    "day I": "1日目",
    "day II": "2日目",
    "comparison type": "比較タイプ",
    "Max Custom Date Range selection is 90 days at a stretch": "最大の特別な日付範囲の選択は立て続けで90日です",
    "Crawling Insect Cumulative Sum": "這う昆虫累計",
    "Rodent Cumulative Sum": "齧歯類の累積合計",
    "Flying Insect Cumulative Sum": "飛翔昆虫累計",
    "glue board expiry": "グルー ボードの有効期限",
    "Wi-Fi": "Wi-Fi",
    "max allowed on glue board": "グルー ボード上で許容される最大値",
    "please select upto current month": "今月まで選択してください",
    "events log": "イベントログ",
    "event": "イベント",
    "month of the year": "今年の月",
    "day of the month": "月の日",
    "day of the week": "曜日",
    "Sensor Management": "センサー管理",
    "LPWAN": "LPWAN",
    "all detections": "すべての検出",
    "Glue Trap": "グルートラップ",
    "Standalone": "スタンドアロン",
    "Bait Station": "ベイトステーション",
    "max": "マックス",
    "years": "年",
    "hr": "時間",
    "hrs": "時間",
    "min": "分",
    "mins": "分",
    "sec": "秒",
    "secs": "秒",
    "ago": "前に",
    "days left": "残りの日数",
    "day left": "残り日",
    "Expired": "期限切れ",
    "days ago": "数日前",
    "day ago": "前日",
    "Entry from Left Side": "左側からのエントリー",
    "Entry from Right side": "右側からのエントリー",
    "Values denote the rodent's direction of entry on the sensor": "値はセンサー上でのげっ歯類の進入方向を示します",
    "Values denote the side of entry of crawling insect on the sensor": "値は、センサー上の這う昆虫の侵入側を示します",
    "Due to variations in size, the side of entry for": "サイズの違いにより、進入方向が異なります。",
    "some rodent detections remains unidentified.": "一部のげっ歯類の検出は未確認のままです。",
    "events": "イベント",
    "added": "追加した",
    "replaced": "交換済み",
    "removed": "削除されました",
    "sensor idle": "センサーアイドル",
    "sensor active": "センサーアクティブ",
    "sensor offline": "センサーオフライン",
    "zone critical control limit breached": "ゾーンクリティカルコントロール制限に違反しました",
    "flying insect max detections breached": "飛翔昆虫の最大検出数が突破されました",
    "flying insect glue board expiry breached": "飛翔昆虫グルー ボードの有効期限違反",
    "sensor established connection": "センサー確立接続",
    "wifi configuration successful": "WiFi構成が成功しました",
    "flying insect glue board replaced": "飛翔昆虫グルー ボードを交換しました",
    "sensor name edited": "編集されたセンサー名",
    "sensor pictures edited": "編集されたセンサー画像",
    "location description edited": "場所説明編集済み",
    "Detections between": "間の検出",
    "in": "の",
    "Hrs": "時間",
    "on": "の上",
    "during": "その間",
    "between": "の間に",
    "hrs on": "何時間も",
    "detection": "検出",
    "Day vs Day": "日対日",
    "Week vs Week": "週対週",
    "Month vs Month": "月対月",
    "Year vs Year": "年対年",
    "Educational Institution": "教育機関",
    "Food Processing": "食品加工",
    "Home": "家",
    "Hospital": "病院",
    "Hotel": "ホテル",
    "Loading Bay Warehouse": "ベイウェアハウスの積み込み",
    "Office": "オフィス",
    "Restaurant": "飲食店",
    "Retail": "小売店",
    "Theatre": "劇場",
    "Airport": "空港",
    "Bar": "バー",
    "Cafe": "カフェ",
    "Cruise line": "クルーズライナー",
    "Supermarket": "スーパーマーケット",
    "Train Station": "でんしゃのりば",
    "glueboard expiry": "グルー ボード有効期限",
    "glueboard max detections": "グルー ボードの最大検出数",
    "zone ccl breached": "ゾーンccl違反",
    "established connection": "確立された接続",
    "established connection wifi": "Wi-Fi接続が確立されました",
    "wifi config successful": "Wi-Fi設定が成功しました",
    "glueboard changed": "グルー ボードが変更されました",
    "detections resolved": "検出が解決されました",
    "Web App": "ウェブアプリ",
    "Mobile App": "モバイルアプリ",
    "sensor add": "センサー追加",
    "sensor edit": "センサー編集",
    "sensor remove": "センサーの取り外し",
    "sensor replace": "センサー交換",
    "Site deleted successfully.": "サイトが正常に削除されました。",
    "Zone deleted successfully.": "ゾーンが正常に削除されました。",
    "Super Admin": "スーパー管理者",
    "Regional Manager": "地域担当マネージャー",
    "Branch Manager": "支店長",
    "Customer Service Executive": "顧客対応責任者",
    "Service Manager": "サービスマネージャ",
    "Service Technician": "サービス技術者",
    "Customer Representative": "顧客担当者",
    "Super Admin is the root level access of the organisation.": "スーパー管理者は、組織のルートレベルのアクセスです。",
    "Regional Manager is the managerial role with scope of multiple branches. Multiple Branch Managers can report under Regional Manager.": "リージョナルマネージャーは、複数の支店にまたがる管理職です。複数のブランチマネージャーは、リージョナルマネージャーの下でレポートできます。",
    "Branch Manager is the managerial role for one branch. Branch manager is empowered with all functions within scope of a branch.": "支店長は、1つの支店の管理者の役割です。ブランチマネージャーは、ブランチのスコープ内のすべての機能を利用できます。",
    "Customer Service Executive is the custodian of achieving customer satisfaction within a branch. One or more Customer Service Executives can manage the customers in a branch as required.": "カスタマーサービスエグゼクティブは、支店内で顧客満足を達成するためのカストディアンです。 1人以上のカスタマーサービスエグゼクティブは、必要に応じて支店の顧客を管理できます。",
    "In large branches, Service Managers manage the routing and productivity of the Service Technicians on the field. One or more Service Managers, manage technicians under them for best utilisation of their time.": "大規模な支店では、サービスマネージャーが現場のサービス技術者のルーティングと生産性を管理します。 1人以上のサービスマネージャーは、時間を最大限に活用するために、その下の技術者を管理します。",
    "Service Technicians (a.k.a PMP, Pest Management Personnel) are the field agents who visit the customers site to deliver the Sensor Installation, Pest Control, Sensor Malfunction and Periodic maintenance services.": "サービス技術者（別名PMP、害虫管理要員）は、センサーの設置、害虫駆除、センサーの誤動作、および定期的なメンテナンスサービスを提供するために顧客のサイトを訪問するフィールドエージェントです。",
    "Customer Representatives are the site\/facility managers of the customer for which the services are provided. A Customer Representative can be mapped to only one customer": "顧客担当者は、サービスが提供される顧客のサイト\/施設管理者です。顧客担当者は、1人の顧客にのみマップできます",
    "site events log": "サイトイベントログ",
    "Site Management": "サイト管理",
    "Zone Management": "ゾーン管理",
    "This sensor ID does not exist! Kindly recheck and try again.": "このセンサーIDは存在しません！もう一度確認して、もう一度お試しください。",
    "Invalid Sensor! This sensor does not belong to your organisation.": "センサーが無効です！このセンサーはあなたの組織のものではありません。",
    "Sensor is validated successfully!": "センサーが正常に検証されました！",
    "Invalid sensor! This sensor is already added elsewhere.": "センサーが無効です！このセンサーはすでに他の場所に追加されています。",
    "Invalid sensor! This sensor has been removed as malfunctioning and can no longer be added.": "センサーが無効です！このセンサーは誤動作として削除され、追加できなくなりました。",
    "Invalid sensor! This sensor has been replaced as malfunctioning \/ damaged \/ lost and can no longer be added.": "センサーが無効です！このセンサーは、誤動作\/損傷\/紛失として交換され、追加できなくなりました。",
    "Invalid sensor! This sensor was added to site": "センサーが無効です！このセンサーはサイトに追加されました",
    " zone": " ゾーン",
    "and is currently in use.": "現在使用中です。",
    "site created": "作成されたサイト",
    "site edited": "編集されたサイト",
    "service team edited": "サービスチーム編集",
    "zone added": "ゾーンが追加されました",
    "zone name edited": "ゾーン名を編集しました",
    "zone deleted": "ゾーンが削除されました",
    "zone ccl changed rodent": "ゾーンCCLは齧歯動物を変更しました",
    "zone ccl changed fly": "ゾーンCCL変更フライ",
    "Zone Rodent critical control limit changed from": "ゾーン齧歯動物の重要な管理限界がから変更されました",
    "Zone Crawling Insect Critical Control Limit changed from": "ゾーン クロール昆虫のクリティカル コントロール リミットが から変更されました",
    "Zone Fly critical control limit changed from": "ゾーンフライのクリティカルコントロールリミットがから変更されました",
    " to ": " に ",
    "sensor added": "センサー追加",
    "delete site": "サイトを削除",
    "delete zone": "ゾーンを削除",
    "zone flying insect critical control limit breached": "ゾーンフライのクリティカルコントロール制限に違反しました",
    "grouped crawling insect detections by site": "サイトごとにグループ化された這う昆虫の検出",
    "grouped rodent detections by site": "サイトごとのグループ化された齧歯動物の検出",
    "grouped fly detections by site": "サイトごとのグループ化された飛翔昆虫の検出数",
    "type": "タイプ",
    "site contact person name": "サイト担当者名",
    "None Selected": "何も選択されていません",
    "All Selected": "選択したすべて",
    "Multiple Selected": "複数選択",
    "glueboard max fly detections": "グルー ボードの飛翔昆虫最大検出数",
    "edited": "編集",
    "sensor photo": "センサー写真",
    "add sensor pictures": "センサー画像を追加",
    "sensor picture(s)": "センサー画像",
    "sensor picture": "センサー画像",
    "flying insect glueboard max detections": "フライグルーボードの最大検出数",
    "flying insect glueboard expiry": "フライグルーボードの有効期限",
    "Sensor WiFi Configuration Successful": "センサーWiFi構成が成功しました",
    "zone crawling insect breached": "ゾーンクロール昆虫違反",
    "zone fly breached": "ゾーンフライ違反",
    "zone rodent breached": "ゾーン齧歯動物が違反しました",
    "flying insect glueboard max detections breached": "フライグルーボードの最大検出数が違反しました",
    "sensor edited": "センサー編集",
    "sensor removed": "センサーが取り外されました",
    "sensor replaced": "センサーを交換",
    "edit service team": "サービスチームの編集",
    "sensor established connection via wifi": "WiFi経由でセンサーが接続を確立",
    "sensor wifi configuration successful": "センサーWiFi構成が成功しました",
    "zone rodent critical control limit edited": "ゾーン齧歯類重要管理限度を編集しました",
    "zone flying insect critical control limit edited": "ゾーン飛翔昆虫重要管理限度を編集しました",
    "zone crawling insect critical control limit edited": "ゾーン這う昆虫重要管理限度が編集されました",
    "zone crawling insect critical control limit breached": "ゾーン這う昆虫重要管理限度を超えました",
    "flying insect glueboard expiry changed": "飛翔昆虫グルー ボードの有効期限が変更されました",
    "flying insect glueboard max detections changed": "飛翔昆虫グルーボードの最大検出数が変更されました",
    "Detections Resolution": "検出の解決",
    "Flying Insect Glueboard Expiry Changed from": "飛翔昆虫グルー ボードの有効期限が変更されました",
    "days to": "までの日数",
    "Days": "日々",
    "Flying Insect Glueboard Max Detections Changed from": "飛翔昆虫グルー ボードの最大検出数が変更されました",
    "site information edited": "編集されたサイト情報",
    "contact person details edited": "担当者の詳細を編集",
    "zone edited": "ゾーン編集",
    "Changed from": "から変更",
    "contact phone number": "連絡先の電話番号",
    "contact email id": "連絡先メールID",
    "zone map edited": "ゾーンマップ編集",
    "Zone Name, Map Edited": "ゾーン名、マップ編集",
    "New Password and Confirm New Password does not match": "新しいパスワードと新しいパスワードの確認が一致しません",
    "You have entered invalid old password.": "無効な古いパスワードを入力しました。",
    "profile": "プロフィール",
    "preferences": "環境設定",
    "New Password shoudn't be same as old password.": "新しいパスワードは古いパスワードと同じであってはなりません。",
    "Flying Insect Glueboard": "フライボード",
    "user roles": "ユーザーの役割",
    "The side of the entry for": "のエントリの側面",
    "rodent detections is unknown due to variations in the size of the rodent.": "齧歯動物のサイズが異なるため、齧歯動物の検出は不明です。",
    "Critically low, requires replacement!": "非常に低く、交換が必要です！",
    "total sites": "総サイト数",
    "100% fully charged": "100％フル充電",
    "remaining": "残り",
    "administration": "管理の概要",
    "created by (username)": "作成者 (ユーザー名)",
    "reporting manager (username)": "上司 (ユーザー名)",
    "Tobacco Beetle Glueboard": "シガレット ビートル グルーボード",
    "Default max cigarette beetle detections allowed on the glueboard": "グルーボードで許容されるデフォルトの最大シガレットビートル検出",
    "please enter max cigarette beetle detections allowed on the glueboard": "グルーボードで許容されるシガレットビートルの最大検出数を入力してください",
    "Default max cigarette beetle detections allowed on the glueboard between 1 to 1999": "グルーボードで許容されるデフォルトの最大シガレットビートル検出数は 1 ～ 1,999 です",
    "Default Tobacco Beetle Critical Control Limit of the Zone": "デフォルト シガレット ビートル ゾーンのクリティカル コントロール リミット",
    "Please Enter Tobacco Beetle Critical Control Limit": "シガレットビートル クリティカル コントロール リミットを入力してください",
    "Default tobacco beetle detections limit between 0 to 1999": "デフォルトのシガレットビートル検出制限は 0 ～ 1,999 です",
    "tobacco beetle": "シガレットビートル",
    "cigarette beetle": "シガレットビートル",
    "max cigarette beetle detections allowed on the glueboard between 1 to 1999": "グルーボードで許容されるシガレットビートルの最大検出数は 1 ～ 1,999 です",
    "max cigarette beetle detections allowed on the glueboard": "グルーボードで許容される最大のシガレットビートル検出",
    "cigarette beetle sensor": "シガレット ビートルセンサー",
    "Cigarette Beetle Detection": "シガレット ビートルの検出",
    "cigarette beetles": "シガレット ビートルズ",
    "Tobacco Beetle Detections": "シガレット ビートルの検出",
    "Tobacco Beetle Trend": "シガレット ビートルトレンド",
    "Cigarette Beetle Critical Control Limit": "シガレット ビートル重要管理限度",
    "Tobacco Beetle Glueboard Expiry": "シガレット ビートル グルー ボードの有効期限",
    "Tobacco Beetle Glueboard Detections": "シガレット ビートルのグルー ボード検出数",
    "Zone Cigarette Beetle Critical Control Limit": "ゾーン シガレット ビートル 重要管理限度",
    "missing mandatory zoneName field": "必須のゾーン名フィールドがありません",
    "Tobacco Beetle Glueboard Expiry Changed from": "シガレット ビートル グルーボードの有効期限が変更されました",
    "Tobacco Beetle Glueboard Max Detections Changed from": "シガレット ビートル グルーボードの最大検出数が変更されました",
    "zone cigarette beetle critical control limit changed from": "ゾーン シガレット ビートル重要管理限度が変更されました",
    "cigarette beetle sensors": "シガレット　ビートルセンサー",
    "tobacco beetle glueboard max detections breached": "シガレット　ビートルグルー ボードの最大検出数を突破",
    "tobacco beetle glueboard expiry breached": "シガレット ビートル グルー ボードの有効期限が切れました",
    "tobacco beetle glueboard expiry changed": "シガレット ビートル グルー ボードの有効期限が変更されました",
    "tobacco beetle glueboard max detections changed": "シガレット ビートル グルー ボードの最大検出数が変更されました",
    "tobacco beetle glueboard replaced": "シガレット ビートル グルー ボードを交換しました",
    "all cigarette beetle sensors": "すべてのタバコビートルセンサー",
    "Tobacco Beetle Cumulative Sum": "シガレット ビートル累計数",
    "detection count": "検出回数",
    "New Tobacco Beetle Detections at Site": "サイトでの新しいシガレット ビートルの検出数",
    "New Tobacco Beetle Detections": "新しいシガレット ビートルの検出数",
    "zone tobacco beetle critical control limit edited": "ゾーン シガレット ビートル重要管理限度を編集しました",
    "zone tobacco beetle critical control limit breached": "ゾーン シガレット ビートル重要管理限度を超えました",
    "grouped cigarette beetle detections by site": "サイト別のグループ化されたシガレット ビートル検出数",
    "max. detections limit on the glueboard": "最大グルー ボードの検出限界",
    "please enter max. detections limit on the glueboard": "グルー ボードの最大検出限度を入力してください",
    "max. detections limit allowed on the glueboard between 1 to 1999": "グルーボードで許可される最大検出数は 1 ～ 1,999 です",
    "enter a value between 0 to 1999": "0 ～ 1999 の値を入力してください",
    "New Crawling Insect Detections": "新しい這う昆虫の検出数",
    "crawling insect sensors": "這う昆虫センサー",
    "Crawling Insect Detections": "這う昆虫の検出",
    "Crawling Insect Detection": "這う昆虫の検出",
    "Crawling Insect Trend": "這う昆虫の傾向",
    "Crawling Insect Activity": "這う昆虫活動",
    "crawling insect sensor": "這う昆虫センサー",
    "Pest activity trend is not computed for the replaced sensor(s).": "害虫の活動傾向は、交換されたセンサーでは計算されません。",
    "Pest activity trend is displayed for all the sites available.": "利用可能なすべてのサイトについて、害虫の活動傾向が表示されます。",
    "Pest activity trend is displayed for all the sites available for the customers.": "顧客が利用できるすべてのサイトについて、害虫の活動傾向が表示されます。",
    "Search by Sensor ID": "センサーIDで検索",
    "Search by Sensor Name": "センサー名で検索",
    "No zone map found! Please upload the zone map to map the sensors on the map.": "ゾーンマップが見つかりません!マップ上にセンサーをマッピングするには、ゾーン マップをアップロードしてください。",
    "max length 50": "最大長 50",
    "max length 100": "最大長 100",
    "max length 30": "最大長 30",
    "max length 16": "最大長 16",
    "Default No. of days for Flying Insect Glue Board Expiry": "飛翔昆虫グルー ボード有効期限のデフォルトの日数",
    "Default max. Flying Insect detections allowed on the glue board": "グルー ボードで許可されているデフォルトの飛翔昆虫最大検出数",
    "Flying Insect Glue Board Expiring Notification (days)": "飛翔昆虫グルー ボードの有効期限切れ通知 (日数)",
    "Default No. of days for Tobacco Beetle Glue Board Expiry": "シガレット ビートル グルー ボードの有効期限のデフォルト日数",
    "Default max. Tobacco Beetle detections allowed on the glue board": "グルー ボードで許可されているデフォルトのシガレット ビートル最大検出数",
    "Tobacco Beetle Glue Board Expiring Notification (days)": "シガレット ビートル グルー ボードの有効期限切れ通知 (日数)",
    "The number of days before a glue board expires and a breach is triggered.": "グルー ボードの有効期限が切れて違反がトリガーされるまでの日数。",
    "The maximum detections allowed on a glue board before a breach is triggered for the sensor.": "センサーの違反が起こる前にグルー ボードで許可される最大検出数",
    "This setting defines the early notification generation - 'X' number of days before the expiry of the glue board.": "この設定は、早期通知の生成を定義します - グルー ボードの有効期限が切れる 'X' 日前。",
    "The maximum rodent detection limit for a zone before a breach is triggered.": "違反が起こる前のゾーンの最大げっ歯類検出限度。",
    "The maximum crawling insect detection limit for a zone before a breach is triggered.": "違反が起こる前のゾーンの這う昆虫の最大検出限度。",
    "The maximum flying insect detection limit for a zone before a breach is triggered.": "違反が起こる前のゾーンの最大飛翔昆虫検出限度。",
    "The maximum tobacco beetle detection limit for a zone before a breach is triggered.": "違反が起こる前のゾーンのシガレット ビートルの最大検出限度",
    "value": "価値",
    "edit setting value": "設定値の編集",
    "Deleting the zone map will reset any existing sensor's mapping on the zone map. Are you sure?": "ゾーン マップを削除すると、ゾーン マップ上の既存のセンサーのマッピングがリセットされます。本気ですか？",
    "mapped sensors": "マッピングされたセンサー",
    "unmapped sensors": "マッピングされていないセンサー",
    "save": "保存",
    "Click & drag each sensor onto the zone floor plan to assign it to the desired location.": "各センサーをクリックしてゾーン フロア プランにドラッグし、目的の場所に割り当てます。",
    "Error occurred during mapping the sensor. Please try again.": "センサー マッピングで占有されているエラー。再びそれを行う！",
    "updated successfully!": "更新成功！",
    "view zone map": "ゾーンマップを見る",
    "upload zone map": "ゾーンマップのアップロード",
    "please enter No. of days for the glueboard expiry notification": "グルー ボードの有効期限通知の日数を入力してください",
    "Default No. of days for the glueboard expiry notification between 1 to 90": "グルーボードの有効期限通知のデフォルトの日数 (1 ～ 999)",
    "please enter No. of days for the tobacco beetle glueboard expiry notification": "シガレット ビートル グルー ボードの有効期限通知の日数を入力してください",
    "Default No. of days for the tobacco beetle glueboard expiry notification between 1 to 90": "シガレット ビートル グルー ボードの有効期限通知のデフォルトの日数 (1 ～ 90)",
    "last seen \/ alerts": "最終確認 \/ アラート",
    "security": "セキュリティ",
    "This setting specifies the number of days for a password to get expired for a user after the user logs into the application for the first time or when it is last changed.": "この設定では、ユーザーがアプリケーションに初めてログインした後、または最後に変更されたときに、ユーザーのパスワードが期限切れになるまでの日数を指定します。",
    "This setting determines if the user password should expire or not.": "この設定は、ユーザー パスワードの有効期限が切れるかどうかを決定します。",
    "Password expiry (days)": "パスワードの有効期限 (日)",
    "Enable password expiry": "パスワードの有効期限を有効にする",
    "please enter password expiry (days)": "パスワードの有効期限 (日) を入力してください",
    "password expiry (days) Min value: 90  to Max: 730": "パスワードの有効期限 (日) 最小値: 90 から最大: 730",
    "please change your password to continue.": "続行するにはパスワードを変更してください。",
    "already added to the service team": "すでにサービスチームに追加されています",
    "undo revoke": "取り消しを元に戻す",
    "This setting defines the early notification generation - \"X\" number of days before the expiry of the glue board.": "この設定は、早期通知の生成を定義します - グルー ボードの有効期限が切れるまでの日数「X」。",
    "enabled": "有効",
    "disabled": "無効",
    "Enable \/ Disable password expiry": "パスワードの有効期限を有効\/無効にする",
    "Please your enter username": "ユーザー名を入力してください",
    "When enabled, this setting specifies the number of days for passwords to get expired since it was created or changed. When the password expiry is enabled (if disabled before), it is reset on the next successful login for all users.": "この設定を有効にすると、パスワードが作成または変更されてから有効期限が切れるまでの日数が指定されます。パスワードの有効期限が有効になっている場合 (以前に無効になっていた場合)、すべてのユーザーが次回ログインに成功したときにリセットされます。",
    "No unmapped sensors exist!": "マッピングされていないセンサーはありません!",
    "Your password has expired! Please change your password to continue.": "パスワードの有効期限が切れています。続行するには、パスワードを変更してください。",
    "tobacco beetle glueboard expiring": "シガレット ビートル グルーボードの有効期限が近づいています",
    "flying insect glueboard expiring": "飛翔昆虫グルー ボード有効期限切れています",
    "Fly Glue Board expiring in": "飛翔昆虫グルー ボードの有効期限が切れます",
    "Cigarette Beetle Glue Board expiring in": "シガレット ビートル グルー ボードの有効期限が切れます",
    "Customer cannot be deleted, as it is associated with one or more sites.": "顧客は 1 つ以上のサイトに関連付けられているため、削除できません。",
    "zone rodent critical control limit breached": "​ゾーン齧歯類の重要な管理限界に違反",
    "update site": "サイトの更新",
    "do you want to remove": "削除しますか",
    "Rodent": "齧歯類",
    "Flying Insect": "飛ぶ",
    "Tobacco Beetle": "シガレットビートル",
    "no records found": "レコードが見つかりません",
    "Your password has been changed successfully! Please login with your new password.": "パスワードが正常に変更されました！新しいパスワードでログインしてください。",
    "Invalid username or password. Please enter valid credentials and try again!": "ユーザー名かパスワードが無効。有効な資格情報を入力して、もう一度お試しください!",
    "Inactive user!": "非アクティブなユーザー！",
    "The new password cannot be the same as the current password.": "新しいパスワードを現在のパスワードと同じにすることはできません。",
    "The current password entered is incorrect!": "現在入力されているパスワードが正しくありません!",
    "sensors info": "センサー情報",
    "breached": "違反した",
    "help": "ヘルプ",
    "Internet of Pests Help Center": "Internet of Pests ヘルプセンター",
    "Go to IoP Dashboard": "IoP ダッシュボードに移動",
    "sensors list": "センサー一覧",
    "The Comm. Status Column": "コム。ステータス列",
    "All sensors communicate with the IoP® platform whenever it detects “pests or in each heartbeat”. The heartbeat time for rodent and crawling sensors is 24 hrs. and for fly and cigarette beetle sensors is 30 mins.": "すべてのセンサーは、「害虫または各心拍」を検出するたびに IoP® プラットフォームと通信します。げっ歯類および這うセンサーの心拍時間は 24 時間です。飛翔昆虫とシガレット ビートルのセンサーの場合は 30 分です。",
    "The Comm. Status or Communication Status lets you know what is the current status of a sensor and whether it’s functional. There are 4 possible values for comm. status:": "Comm. Status または Communication Status により、センサーの現在のステータスと、センサーが機能しているかどうかを知ることができます。 comm には 4 つの可能な値があります。スターテス：",
    "Displays when a sensor is newly added to your account and has not yet been switched “On”.": "センサーがアカウントに新しく追加され、まだ「オン」になっていない場合に表示されます。",
    "Displays when a sensor is online and has detected any pest or has sent a “heartbeat” message in the last 25 hours (for Rodent and Crawling Insect sensors) or in the last 40 minutes (for Fly and Cigarette Beetle sensors)": "センサーがオンラインで、過去 25 時間以内 (げっ歯類および這う昆虫センサーの場合)、または過去 40 分間 (飛翔昆虫およびシガレット ビートル センサーの場合) に害虫を検出したか、「ハートビート」メッセージを送信したときに表示されます。",
    "Displays when a sensor has not detected any pest or has not sent a “heartbeat” message between last 25 hours and 48 hours (for Rodent and Crawling Insect sensors) or between last 40 minutes and 60 minutes (for Fly and Cigarette Beetle sensors)": "過去 25 時間から 48 時間 (げっ歯類および這性昆虫センサーの場合)、または過去 40 分から 60 分 (飛翔昆虫およびシガレット ビートル センサーの場合) の間、センサーが害虫を検出しなかった場合、または「ハートビート」メッセージを送信しなかった場合に表示されます。",
    "Displays when a sensor has not detected any pest or has not sent a “heartbeat” message for more than 48 hours (for Rodent and Crawling Insect sensors) or for more than 60 minutes (for Fly and Cigarette Beetle sensors)": "センサーが害虫を検出しなかった場合、または「ハートビート」メッセージを 48 時間以上 (げっ歯類および這う昆虫センサーの場合) または 60 分以上 (飛翔昆虫およびシガレットビートル センサーの場合) 送信しなかった場合に表示されます。",
    "The Last Seen\/Alerts Column": "最後に見たもの \/ アラート カラム",
    "The Last Seen\/Alerts column lets you know the last time and date an update (communication) was received from the sensor. It also includes three types of alert icons.": "[最後に見たもの \/ アラート] 列では、センサーから最後に更新 (通信) を受信した日時を確認できます。また、3 種類の警告アイコンも含まれています。",
    "The New Detections Column": "新しい検出列",
    "The new detections column displays the number of “triggers” or pests the sensor has detected since the last time they were resolved. These are also displayed on the Detections List screen from where the Pest Management Personnel can resolve them.": "新しい検出列には、センサーが最後に解決されてから検出した「トリガー」またはペストの数が表示されます。これらは、害虫管理担当者がそれらを解決できる場所から検出リスト画面にも表示されます。",
    "How often does the data on the sensor’s list page refresh?": "センサーのリスト ページのデータはどのくらいの頻度で更新されますか?",
    "By default, the page will refresh automatically when the sensor detects pests or changes it's communication status.": "デフォルトでは、センサーが害虫を検出したり、通信ステータスが変化したりすると、ページが自動的に更新されます。",
    "sensors list help": "センサーリストのヘルプ",
    "Sensor Communication Channel": "センサー通信チャネル",
    "LPWAN or Wi-Fi - if a sensor is communicating over an LPWAN network or is configured to communicate over a local Wi-Fi network.": "LPWAN または Wi-Fi - センサーが LPWAN ネットワーク経由で通信しているか、ローカル Wi-Fi ネットワーク経由で通信するように構成されている場合。",
    "Critical Battery Status": "重大なバッテリー状態",
    "Breach Status": "違反状況",
    "when a zone with the specific pest type has exceeded (breached) the detections or Critical Control Limit for a specific pest.": "特定の害虫タイプのゾーンが、特定の害虫の検出または重要管理限界を超えた (違反した) 場合。",
    "when a Fly or Cigarette Beetle sensor's glue board has expired. The glue board expiry is calculated from the date the sensor was added to the platform.": "飛翔昆虫またはシガレット ビートル センサーのグルー ボードの有効期限が切れたとき。グルー ボードの有効期限は、センサーがプラットフォームに追加された日付から計算されます。",
    "when a Fly or Cigarette Beetle sensor's max detections limit on the glue board has exceeded.": "グルー ボードでの飛翔昆虫またはシガレット ビートル センサーの最大検出限度を超えた場合。",
    "Rodent and Crawling Insect sensors contains a lithium-ion non rechargeable cell that is permanently pre-installed for the 7 years or 12,000 transmissions expected operational lifetime.": "げっ歯類および這う昆虫センサーにはリチウムイオン非充電式セルが含まれており、7年または12,000 回の送信が予想される動作寿命に対して永久的に事前に取り付けられています。",
    "A critical battery status is displayed when a sensor's battery is below 10%. This percentage value is calculated based on the total number of “triggers” generated by the sensor; i.e. (12,000 - Total Triggers) %.": "センサーのバッテリーが 10% を下回ると、重大なバッテリー ステータスが表示されます。このパーセンテージ値は、センサーによって生成された「トリガー」の総数に基づいて計算されます。つまり、(12,000 - トリガーの合計) %。",
    "The total triggers is maintained from the time the sensor was first turned on.": "合計トリガーは、センサーが最初にオンになった時点から維持されます。",
    "7 Days": "7日",
    "30 Days": "30日",
    "no matches": "一致しません",
    "valid format JPG\/JPEG\/PNG": "有効な形式 JPG\/JPEG\/PNG",
    "max file size: 5MB": "最大ファイルサイズ: 5MB",
    "Filter data by selecting the legends": "凡例を選択してデータをフィルター処理する",
    "day vs day": "日対日",
    "week vs week": "週対週",
    "month vs month": "月対月",
    "year vs year": "年対年",
    "Aggregated for": "集計対象",
    "Filters": "フィルター",
    "zone map successfully updated.": "ゾーン マップが正常に更新されました。",
    "English": "English",
    "Japanese": "日本",
    "Spanish": "Español",
    "submit": "送信",
    "Did you forgot your password?": "パスワードを忘れましたか?",
    "Submit your Username or registered Email ID and you will receive a temporary password to login.": "ユーザー名または登録済みの電子メール ID を送信すると、ログイン用の一時パスワードが届きます。",
    "Back to Sign in": "サインインに戻る",
    "Temporary password has been sent to your email successfully.": "一時パスワードがメールに送信されました。",
    "Invalid username/email id" : "無効なユーザー名/メール ID",
    "Username \/ Email ID": "ユーザー名\/メールID",
    "please enter your username \/ email id": "ユーザー名\/メールIDを入力してください",
    "site pest detections report": "現場の害虫検出レポート",
    "No unMapped sensor": "マップされていないセンサーはありません",
    "Hourly cumulative detections": "時間ごとの累積検出数",
    "Percentage detections": "検出率",
    "Sensor name": "センサー名",
    "count": "カウント",
    "total detections": "総検出数",
    "Please select the customer, site, date range and time zone to generate the report.": "レポートを生成する顧客、サイト、日付範囲、およびタイムゾーンを選択してください。",
    "system error!": "システムエラー！",
    "download": "ダウンロード",
    "site address": "サイトアドレス",
    "site timezone": "サイトアドレス",
    "Pest Management Company": "害害虫管理会社虫会社",
    "Evoscien Main Office": "Evoscien Main Office",
    "generated on": "にダウンロードされました",
    "generated by": "によってダウンロードされました",
    "Date range": "日付範囲",
    "Time zone": "タイムゾーン",
    "Powered by": "搭載",
    "This report was generated": "このレポートは生成されました",
    "Percentage change": "変化率",
    "generate report": "レポートの生成",
    "stakeholder performance analysis": "ステークホルダーのパフォーマンス分析",
    "Sort by Site": "サイトごとに並べ替え",
    "Sort by Zone": "ゾーンごとに並べ替え",
    "Week I: Starting Date Of": "第 1 週: 開始日",
    "Week II: Starting Date Of": "第 2 週: 開始日",
    "Please select the customer, pest and comparison type and time zone to generate the report.": "レポートを生成するには、顧客、害虫、比較のタイプとタイムゾーンを選択してください。",
    "deleted": "削除されました",
    "original day": "元の日",
    "final day": "最終日",
    "Original Day should be less than Final Day": "元の日は最終日よりも前の日である必要があります",
    "Final Day should be greater than Original Day": "最終日は元の日よりも大きい必要があります",
    "original week": "オリジナルウィーク",
    "final week": "最終週",
    "Original Week should be less than Final Week": "元の週は最終週よりも小さい必要があります",
    "Final Week should be greater than Original Week": "最終週は元の週より大きくなければなりません",
    "original month": "元の月",
    "final month": "最終月",
    "Original Month should be less than Final Month": "元の月は最終月よりも小さい必要があります",
    "Final Month should be greater than Original Month": "最終月は元の月より大きくなければなりません",
    "original year": "元の年",
    "final year": "最終年",
    "Original Year should be less than Final Year": "元の年は最終年よりも小さい必要があります",
    "Final Year should be greater than Original Year": "最終年は元の年より大きい必要があります",
    "Please select the customer and pest to generate the report.": "レポートを生成する顧客と害虫を選択してください。",
    "progress": "進捗",
    "success": "成功",
    "failed": "失敗した",
    "retry": "リトライ",
    "status": "状態",
    "percentage detections": "検出率",
    "faqs": "よくある質問",
    "What is remote pest monitoring? Why is it important?": "害虫の遠隔監視とは何ですか?どうしてそれが重要ですか？",
    "Remote pest monitoring technology allows pest management professionals to track and monitor pest activity from anywhere in real-time via mobile or web-based platforms. It allows pest control technicians to quickly identify and treat hot spots on the premises, potentially reducing labour time and chemical use.": "リモート害虫監視テクノロジーにより、害虫管理の専門家は、モバイルまたは Web ベースのプラットフォームを介して、どこからでもリアルタイムで害虫の活動を追跡および監視できます。これにより、害虫駆除技術者は敷地内のホットスポットを迅速に特定して処理できるため、労働時間と化学薬品の使用が削減される可能性があります。",
    "What is the point of using sensors without a trap?": "トラップなしでセンサーを使用することに何の意味があるのでしょうか?",
    "The sensor’s flexible form factor allows it to be placed in usually inaccessible areas, such as roof spaces and ventilation shafts, where they can provide early warning of potential pest breeding sites and threats to infrastructure.": "このセンサーの柔軟なフォームファクターにより、屋根スペースや換気シャフトなど、通常はアクセスできないエリアに設置することができ、潜在的な害虫の繁殖場所やインフラへの脅威を早期に警告できます。",
    "Can a single sensor detect rodents, crawling, and flying insects?": "単一のセンサーでげっ歯類、這う昆虫、飛んでいる昆虫を検出できますか?",
    "No. While the underlying technology is the same, each sensor is designed for a particular pest type.": "いいえ、基礎となるテクノロジーは同じですが、各センサーは特定の害虫の種類に合わせて設計されています。",
    "Will my sensor battery last 7 years? Are the batteries replaceable?": "センサーのバッテリーは 7 年間持続しますか?電池は交換可能ですか?",
    "The expected battery life of the rodent and crawling pest sensors is 7 years or 12,000 transmissions. Actual battery life would vary depending on the frequency of pest activity in the area. Batteries can only be replaced by a trained technician.": "げっ歯類および這う害虫センサーの予想バッテリー寿命は 7 年または 12,000 回の送信です。実際のバッテリー寿命は、その地域での害虫の活動の頻度によって異なります。バッテリーの交換は訓練を受けた技術者のみが行うことができます。",
    "Flying insect sensors are powered by the main power supply, and do not require batteries.": "飛翔昆虫センサーは主電源から電力を供給されるため、電池は必要ありません。",
    "Do I need Wi-Fi for my IoP® setup?": "IoP® セットアップには Wi-Fi が必要ですか?",
    "A Wi-Fi or ethernet connection is only required for the local gateway to receive signals from the rodent and crawling pest sensors. Wi-Fi is preferred for flying insect sensors, but they can transmit data to rodents and crawling insect gateways.": "Wi-Fi またはイーサネット接続は、ローカル ゲートウェイがげっ歯類および這う害虫センサーから信号を受信する場合にのみ必要です。飛翔昆虫センサーには Wi-Fi が推奨されますが、げっ歯類や這う昆虫のゲートウェイにデータを送信する可能性があります。",
    "Since data transmission is minimal, low-data pre-paid SIMs can be used if local Wi-Fi or ethernet connections are unavailable.": "データ送信が最小限であるため、ローカル Wi-Fi またはイーサネット接続が利用できない場合でも、低データ通信量のプリペイド SIM を使用できます。",
    "Can I add, relocate, or remove sensors if needed?": "必要に応じてセンサーを追加、移動、または削除できますか?",
    "Yes, the IoP® system allows seamless sensor addition, removal, replacement, and relocation without data loss. For more information, refer to the Technician’s Handbook.": "はい、IoP® システムを使用すると、データを損失することなくセンサーの追加、削除、交換、再配置をシームレスに行うことができます。詳細については、技術者用ハンドブックを参照してください。",
    "What do I do if my sensors stop working?": "センサーが機能しなくなったらどうすればよいですか?",
    "In the absence of pest activity, sensors transmit a heartbeat every 24 hours to indicate their functionality. Contact your provider to repair or replace the sensor if no heartbeat signal is received, and the sensor status changes to idle or offline.": "害虫の活動がない場合、センサーは 24 時間ごとに心拍を送信し、その機能を示します。ハートビート信号が受信されず、センサーのステータスがアイドルまたはオフラインに変化した場合は、プロバイダーに連絡してセンサーを修理または交換してください。",
    "How do I sign up for an IoP® account?" : "IoP® アカウントにサインアップするにはどうすればよいですか?",
    "Your IoP® super-admin account will be generated upon confirmation of your order. Contact our support team for more information, or to request a demo." : "IoP® スーパー管理者アカウントは、注文の確認時に生成されます。詳細について、またはデモをリクエストするには、サポート チームにお問い合わせください。",
    "I’ve received a breach alert. What do I do next?": "侵害警告を受け取りました。次は何をすればいいでしょうか？",
    "Critical control limits refer to the acceptable level of pest activity. The values can be manually adjusted per pest according to customer requirements and\/or local regulations. An alert is sent when sensor detections exceed the critical control limit. Breach alerts call for immediate action by your pest control service provider.": "重要防除限界とは、害虫の活動の許容可能なレベルを指します。値は、顧客の要件および/または地域の規制に従って、害虫ごとに手動で調整できます。センサーの検出が重要な管理限界を超えると、アラートが送信されます。侵入アラートは、害虫駆除サービスプロバイダーによる即時の行動を求めます。",
    "What type of alerts does the IoP® platform provide?": "IoP® アカウントにサインアップするにはどうすればよいですか?",
    "The IoP® platform sends real-time alerts for critical control limit breaches and sensor communication status updates, including idle\/offline\/active, site-related alerts like site access granted, site detections resolved, and zone-related alerts when pest detections are resolved in a zone.": "IoP® プラットフォームは、アイドル/オフライン/アクティブ、サイトへのアクセス許可、サイト検出の解決などのサイト関連アラート、害虫検出が解決されたときのゾーン関連アラートなど、重大な管理限界違反とセンサー通信ステータスの更新に関するリアルタイムのアラートを送信します。ゾーンで。",
    "How does IoP® help identify pest entry points?": "IoP® は害虫の侵入ポイントを特定するのにどのように役立ちますか?",
    "IoP® sensors provide valuable insight into pest movement patterns by recording which side of the sensor the pest contacted first. Taken together, this data can help PMPs to identify and target potential breeding sites and points of entry.": "IoP® センサーは、害虫が最初にセンサーのどちら側に触れたかを記録することで、害虫の移動パターンに関する貴重な洞察を提供します。総合すると、このデータは、PMP が潜在的な繁殖地と侵入ポイントを特定してターゲットにするのに役立ちます。",
    "Does the IoP® platform provide documentation for regulatory compliance?": "IoP® プラットフォームは規制遵守のための文書を提供しますか?",
    "Yes, all pest activity is securely stored and accessible on the IoP® platform anytime. Users can generate and view historical and current pest activity reports to demonstrate regulatory compliance.": "はい、すべての害虫の活動は安全に保存され、IoP® プラットフォームでいつでもアクセスできます。ユーザーは、過去および現在の害虫活動レポートを生成および表示して、法規制への準拠を実証できます。",
    "Where can the sensors be used?": "センサーはどこで使用できますか?",
    "The sensors can be deployed in areas prone to pest activity, like kitchens, storage areas, waste disposal areas, etc. They can also be used in hard-to-access areas like roof spaces and ventilation ducts.": "このセンサーは、キッチン、保管エリア、廃棄物処理エリアなど、害虫の活動が起こりやすいエリアに設置できます。また、屋根スペースや換気ダクトなどのアクセスが難しいエリアでも使用できます。",
    "Is the sensor waterproof?": "センサーは防水ですか？",
    "Yes, the sensor is IP67 rated, but we recommend turning the unit off and drying it thoroughly before reuse to reduce the possibility of false detections.": "はい、センサーは IP67 等級ですが、誤検出の可能性を減らすために、再使用する前にユニットの電源を切り、完全に乾燥させることをお勧めします。",
    "If you have more queries please click below our official website": "さらに質問がある場合は、以下をクリックして当社の公式ウェブサイトをご覧ください",
    "Follow us on": "フォローしてください",
    "Download Apps": "アプリをダウンロードする",
    "User Guides": "ユーザーガイド",
    "IoP® Platform User Manual": "IoP® プラットフォーム ユーザーマニュアル",
    "IoP® Technician’s Handbook": "IoP® 技術者向けハンドブック",
    "Frequently Asked Questions": "よくある質問",
    "Visit": "訪問",
    "IoP® website": "IoP®ウェブサイト",
    "for more information": "詳細については",
    "Sensor detected possible pest shelter or entrance point.": "センサーが害虫の避難所または侵入口の可能性を検出しました。",
    "Sensors detected possible food source or entrance point.": "センサーが食物源または侵入口の可能性を検出しました。",
    "Error: Unable to connect to the backend server.": "エラー: バックエンド サーバーに接続できません。",
    "Sensor type not enabled: Crawling Insect": "センサーのタイプが有効になっていません: 這う昆虫",
    "Sensor type not enabled: Rodent": "センサーのタイプが有効になっていません: げっ歯類",
    "Sensor type not enabled: Fly": "センサータイプが有効になっていません: フライ",
    "Sensor type not enabled: Cigarette Beetle": "センサータイプが有効になっていません:シガレット ビートル",
    "Zone Events": "ゾーンイベント",
    "zone live monitoring disabled": "ゾーンのライブ監視が無効になっています",
    "zone live monitoring enabled": "ゾーンのライブ監視が有効になっています",
    "live monitoring toggle": "ライブモニタリングの切り替え",
    "live monitoring": "ライブモニタリング",
    "live monitoring disabled": "ライブモニタリングが無効です",
    "live monitoring enabled": "ライブモニタリングを有効にする",
    "enable live monitoring": "ライブモニタリングを有効にする",
    "Enabling live monitoring will allow pest detections and alerts in real-time from being updated on the platform.": "ライブモニタリングを有効にすると、害虫の検出とアラートがプラットフォーム上でリアルタイムに更新されるようになります。",
    "I confirm that I have turned ON all the sensors and they are reporting Active.": "すべてのセンサーがオンになっており、アクティブであると報告されていることを確認します。",
    "successfully enabled the live monitoring": "ライブモニタリングが正常に有効になりました",
    "disable live monitoring": "ライブモニタリングを無効にする",
    "Disabling live monitoring will ignore any pest detections that would be sent by the sensors and will prevent real-time detections and alerts from being updated on the platform.": "ライブモニタリングを無効にすると、センサーから送信される害虫の検出が無視され、プラットフォーム上でリアルタイムの検出とアラートが更新されなくなります。",
    "I confirm to disable live monitoring for this zone.": "このゾーンのライブ監視を無効にすることを確認します。",
    "Daily heartbeat and power ON messages will NOT be ignored and the application will continue to update the Communication Status and Last Seen Timestamp for all the sensors in this zone.": "毎日のハートビートと電源オンのメッセージは無視されず、アプリケーションはこのゾーン内のすべてのセンサーの通信ステータスと最終検出タイムスタンプを更新し続けます。",
    "successfully disabled the live monitoring": "ライブモニタリングが正常に無効になりました",
    "sensor event rejected due to disabled zone live monitoring": "無効なゾーンライブモニタリングによりセンサーイベントが拒否されました",
    "Warning! Zone Live monitoring is currently disabled. Last sensor detection was": "警告！ゾーンのライブ監視は現在無効になっています。最後のセンサー検出は",
    "Enable Live Monitoring for the zone to start logging  sensor detections.": "ゾーンのライブ モニタリングを有効にして、センサー検出のログを開始します。",
    "The following sensors are not Active. Please cycle the sensor power and ensure that they are reporting Active in the application before you Enable Live Monitoring for this zone.": "次のセンサーはアクティブではありません。このゾーンのライブ モニタリングを有効にする前に、センサーの電源を入れ直し、アプリケーションでセンサーがアクティブであることを報告していることを確認してください。",
    "I acknowledge the above issues and still want to enable live monitoring.": "私は上記の問題を認識しており、ライブモニタリングを有効にしたいと考えています。",
    "The following Active sensors were last seen before Live Monitoring for this Zone was Disabled on": "次のアクティブ センサーは、このゾーンのライブ モニタリングが無効になる前に最後に確認されました。",
    ". If you may have switched OFF the following sensors, then please switch them back ON and ensure that they are reporting Active in the application before you Enable Live Monitoring for this zone.": "。次のセンサーのスイッチをオフにした可能性がある場合は、このゾーンのライブ モニタリングを有効にする前に、スイッチをオンに戻し、アプリケーションでセンサーがアクティブであることを報告していることを確認してください。",
    "remove floor map": "フロアマップを削除",
    "Live Monitoring": "ライブモニタリング",
    "for the zone where you are adding the sensor is": "センサーを追加するゾーンの場合は、",
    "Disabled": "無効",
    "Please": "お願いします",
    "Enable": "有効にする",
    "after you have added and turned ON all the sensors in this zone to monitoring pest detections": "害虫の検出を監視するために、このゾーン内のすべてのセンサーを追加してオンにした後",
    "Remove Zone Map": "ゾーンマップの削除",
    "Live Monitoring for this zone is": "このゾーンのライブモニタリングは",
    "disabled.": "無効。",
    "After you have added and turned ON all the sensors in this zone;": "このゾーン内のすべてのセンサーを追加してオンにした後、",
    "enable ": "有効にする ",
    "Live Monitoring to monitor pest detections.": "害虫の検出を監視するライブモニタリング。",
    "Disabled Live Monitoring Zones": "無効化されたライブ監視ゾーン",
    "Zone Map Removed Successfully": "ゾーン マップが正常に削除されました",
    "Upload zone map": "ゾーンマップをアップロードする",
    "No zone map found!": "ゾーンマップが見つかりません!",
    "last updated timestamp": "最終更新タイムスタンプ",
    "Zone Map Uploaded Successfully": "ゾーン マップが正常にアップロードされました",
    "all roles": "すべての役割",
    "no detections reported!": "検出は報告されていません!",
    "Removing the zone map will un-map all the currently mapped sensor and delete the zone map. Are you sure you want to remove the zone map?": "ゾーン マップを削除すると、現在マッピングされているすべてのセンサーのマッピングが解除され、ゾーン マップが削除されます。ゾーン マップを削除してもよろしいですか?",
    "Successfully added sensor(s).": "センサーが正常に追加されました。",
    "Click here": "ここをクリック",
    "to map these sensors on the zone map.": "これらのセンサーをゾーン マップにマッピングします。",
    "is currently disabled": "現在無効になっています",
    "for the": "のために",
    "click here ": "ここをクリック",
    "live monitoring for this zone only after turning": "このゾーンのライブ監視は回転後にのみ行われます",
    "zone to prevent any false detections during handling of the sensors." : "センサーの取り扱い中の誤検知を防ぐためのゾーン。",
    "ON": "の上",
    "the newly added sensors.": "新しく追加されたセンサー。",
    "is not mapped on the zone map": "ゾーンマップにマッピングされていません",
    "latest sensor image": "最新のセンサー画像",
    "Username must be 5-20 characters long and must not include any spaces.": "ユーザー名は 5 ～ 20 文字にする必要があり、スペースを含めることはできません。",
    "please enter phone number": "有効な電話番号を入力してください",
    "flying insect glueboard replaced": "飛翔昆虫グルー ボードを交換しました",
    "First Name must be between 2 and 50 chars.": "名は 2 ～ 50 文字にする必要があります。",
    "Last Name must be between 2 and 50 chars.": "姓は 2 ～ 50 文字にする必要があります。",
    "Customer Name must be between 2 and 50 chars.": "電話番号は 30 文字を超えることはできません。",
    "Street address  must be between 5 and 250 chars.": "顧客名は 2 ～ 50 文字にする必要があります。",
    "City must be between 2 and 50 chars.": "住所は 5 ～ 100 文字にする必要があります。",
    "State must be between 2 and 50 chars.": "市区町村は 2 ～ 50 文字にする必要があります。",
    "Country must be between 2 and 50 chars.": "状態は 2 ～ 50 文字でなければなりません。",
    "Postal code must be between 4 and 10 chars.": "郵便番号は 4 ～ 10 文字にする必要があります。",
    "Name must be between 2 and 50 chars.": "名前は 2 ～ 50 文字にする必要があります。",
    "Site Name must be between 2 and 50 chars.": "サイト名は 2 ～ 50 文字にする必要があります。",
    "Zone name must be between 2 and 50 chars.": "ゾーン名は 2 ～ 50 文字にする必要があります。",
    "Sensor ID must be between 6 and 16 chars.": "センサー ID は 6 ～ 16 文字である必要があります。",
    "Sensor name must be between 2 and 25 chars.": "センサー名は 2 ～ 25 文字にする必要があります。",
    "Description must be between 2 and 100 chars.": "説明は 2 ～ 100 文字にする必要があります。",
    "flying insect glueboard expiry breached": "飛翔昆虫グルー ボードの有効期限が切れました",
    "Email ID already taken. Please enter a different one.": "メール ID はすでに取得されています。別のものを入力してください。",
    "sensor scanned": "センサーがスキャンされました",
    "scanned": "スキャン済み",
    "flying insect sensor": "飛翔昆虫センサー",
    "personalization": "パーソナライゼーション",
    "setting value options": "値のオプションを設定する",
    "Indicates the format that dates are displayed on the web and mobile applications.": "Web およびモバイル アプリケーションで日付が表示される形式を示します。",
    "Indicates the format that times are displayed on the web and mobile applications.": "Web およびモバイル アプリケーションで時刻を表示する形式を示します。",
    "Date Format": "日付形式",
    "Time Format": "時間の形式",
    "please enter a valid phone number": "有効な電話番号を入力してください",
    "Timestamp": "タイムスタンプ",
    "The entry points can only be determined with 2 or more sensors.": "エントリ ポイントは 2 つ以上のセンサーでのみ判断できます。",
    "edit zone": "ゾーンの編集",
    "add zone": "ゾーンの追加",
    "Zone Created Successfully": "ゾーンが正常に作成されました",
    "Zone Updated Successfully": "ゾーンが正常に更新されました",
    "You've reached the maximum limit of 30 zones that can be created.": "作成できるゾーンの最大数である 30 個に達しました。",
    "no zone available": "利用可能なゾーンがありません",
    "to add a zone.": "ゾーンを追加します。",
    "Customer/Site Access Control": "顧客/サイトのアクセス制御",
    "Stakeholder Performance Report" : "ステークホルダーのパフォーマンスレポート",
    "Pest Infestation Summary Report" : "害虫侵入概要レポート",
    "Hours Distribution Report" : "時間分布レポート",
    "Time Series Report" : "時系列レポート",
    "Cumulative Sum Report" : "累計レポート",
    "Time Series Comparison Report" : "時系列比較レポート",
    "vs" : "対",
    "Download PDF File" : "PDFファイルをダウンロード",
    "group by" : "グループ化",
    "export" : "輸出",
    "Remaining Battery" : "バッテリー残量",
    "Estimated Battery Life" : "推定バッテリー寿命",
    "The Remaining battery percentage is calculated as: (Remaining transmissions / 12,000) × 100. This shows the remaining capacity of the sensor's battery based on its total usage." : "バッテリー残量のパーセンテージは、(残りの送信回数 / 12,000) × 100 として計算されます。これは、合計使用量に基づいたセンサーのバッテリーの残量を示します。",
    "months" : "月",
    "Transmissions Overview" : "トランスミッションの概要",
    "The total number of transmissions received from the sensor since it was first added to the account." : "最初にアカウントに追加されてからセンサーから受信した送信の合計数。",
    "Remaining transmissions are calculated by subtracting the total transmissions received from the sensor from the maximum allowable limit of 12,000." : "残りの送信数は、最大許容制限の 12,000 からセンサーから受信した合計送信数を減算して計算されます。",
    "The daily rate is calculated by dividing the total number of transmissions received from the sensor over the last 3 months by 90 days." : "日次レートは、過去 3 か月間にセンサーから受信した送信の合計数を 90 日で割ることによって計算されます。",
    "Right" : "右",
    "Left" : "左",
    "Center" : "中心",
    "Received" : "受け取った",
    "Daily Rate" : "日額料金",
    "transmission/day" : "送信/日",
    "transmissions/day" : "送信/日",
    "End of life, replace immediately!" : "寿命ですので、すぐに交換してください。",
    "This sensor cannot be added as it has been previously used and its battery is critically low. Please replace the battery or use a new sensor." : "このセンサーは以前に使用されており、バッテリーが非常に少ないため追加できません。電池を交換するか、新しいセンサーを使用してください。",
    "TRANSMISSION_TOOLTIP_WITH_RATE": "推定バッテリー寿命は、過去 90 日間の合計送信数を 90 で割ったものから導出される、1 日あたりの送信レート {{dailyRate}} 回/日を使用して計算されます。",
    "TRANSMISSION_TOOLTIP_NO_RATE": "推定バッテリー寿命は、過去 90 日間の合計送信量を 90 で割って求められる 1 日あたりの送信速度を使用して計算されます。",
    "Sensor name can only include numbers, spaces, periods, underscores, colons, hyphens, and parentheses. It cannot start or end with a space." : "センサー名には、数字、スペース、ピリオド、アンダースコア、コロン、ハイフン、括弧のみを含めることができます。スペースで開始または終了することはできません。",
    "sensor validation tests" : "センサー検証テスト",
    "Test Number" : "試験番号",
    "Deployment" : "導入",
    "Test Frequency" : "テスト頻度",
    "Test Date" : "試験日",
    "Detection Count" : "検出数",
    "Video Time Window" : "ビデオ時間ウィンドウ",
    "Video URL" : "動画のURL",
    "Please select the test to generate the report." : "レポートを生成するテストを選択してください。",
    "test" : "テスト",
    "all test" : "すべてのテスト",
    "Please enter passwords to protect the PDF" : "PDF を保護するためのパスワードを入力してください",
    "Please rotate your device to landscape view for the best experience." : "最高のエクスペリエンスを得るために、デバイスを横向き表示に回転してください。",
    "all status" : "すべてのステータス",
    "inactive" : "非アクティブ",
    "Are you sure you want to activate this user" : "このユーザーをアクティブ化してもよろしいですか",
    "Are you sure you want to inactivate this user" : "このユーザーを非アクティブ化してもよろしいですか",
    "inactivate user" : "ユーザーを非アクティブ化する",
    "Select Option": "オプションの選択",
    "Update Reporting Manager" : "レポートマネージャーの更新",
    "The selected user has direct reportees. To proceed with disabling this user, please choose one of the following options:": "選択したユーザーには直属の部下がいます。このユーザーの無効化を続行するには、次のオプションのいずれかを選択してください。",
    "Reassign Reportees" : "報告者の再割り当て",
    "Proceed Without Reassignment" : "再割り当てなしで続行",
    "Select a new reporting manager for the affected users before disabling the current user." : "現在のユーザーを無効にする前に、影響を受けるユーザーの新しいレポート マネージャーを選択してください。",
    "Disable this user without reassigning the direct reportees to a new reporting manager." : "直属の部下を新しいレポートマネージャーに再割り当てせずに、このユーザーを無効にします。",
    "Reportees of" : "の報告者",
    "Select New Reporting Manager" : "新しいレポートマネージャーを選択します",
    "I confirm to disable the current user and reassign all reportees to the selected reporting manager." : "現在のユーザーを無効にし、すべての報告対象者を選択したレポート マネージャーに再割り当てすることを確認します。",
    "I confirm to inactivate this user without changing the reporting manager of the above users." : "上記のユーザーのレポート管理者を変更せずに、このユーザーを非アクティブ化することを確認します。",
    "No eligible reporting managers available." : "適格なレポートマネージャーがいません。",
    "There are no users with an equivalent role who can be assigned as the reporting manager. You can either create a new user with the same role to reassign the reportees or proceed to deactivate this user without reassignment." : "レポートマネージャーとして割り当てられる同等の役割を持つユーザーは存在しません。同じロールを持つ新しいユーザーを作成して報告者を再割り当てすることも、再割り当てせずにこのユーザーを非アクティブ化することもできます。",
    "Reporting Manager Updated Successfully." : "Reporting Manager が正常に更新されました。",
    "Successfully inactivated this user." : "このユーザーは正常に非アクティブ化されました。",
    "Successfully activated this user." : "このユーザーは正常にアクティブ化されました。",
    "please select the customer." : "お客様を選択してください。",
    "First Detection Direction" : "最初の検出方向",
    
    "sensor validation" : "センサーの検証",
    "list" : "リスト",
    "report" : "報告",
    "test execution list" : "テスト実行リスト",
    "all objective" : "すべての目的",
    "objective" : "客観的",
    "species" : "種",
    "sensor firmware version" : "センサーのファームウェアのバージョン",
    "test execution number" : "テスト実行番号",
    "create test execution" : "テスト実行の作成",
    "total test runs" : "合計テスト実行数",
    "please enter total test runs" :  "合計テスト実行回数を入力してください",
    "min value 1 & max value 100" : "最小値 1 & 最大値 100",
    "min value 1 & max value 50" : "最小値 1 & 最大値 50",
    "test description" : "テストの説明",
    "temperature" : "温度",
    "please enter temperature" : "温度を入力してください",
    "humidity" : "湿度",
    "please enter humidity" : "湿度を入力してください",
    "remarks" : "備考",
    "start" : "始める",
    "end" : "終わり",
    "create & test" : "作成とテスト",
    "create & start" : "作成して開始",
    "Test execution successfully created." : "テスト実行が正常に作成されました。",
    "Test execution successfully created and started." : "テスト実行が正常に作成され、開始されました。",
    "Test execution has been successfully updated." : "テストの実行が正常に更新されました。",
    "Created Date" : "作成日",
    "firmware version" : "ファームウェアのバージョン",
    "Test execution started successfully." : "テストの実行が正常に開始されました。",
    "Test execution ended successfully." : "テストの実行は正常に終了しました。",
    "Test execution deleted successfully." : "テスト実行は正常に削除されました。",
    "do you want to delete" : "削除しますか",
    "Please select a customer, site, and zone to generate the sensor list." : "センサー リストを生成するには、顧客、サイト、ゾーンを選択してください。",
    "test execution details" : "テスト実行の詳細",
    "reset filters" : "フィルターをリセットする",
    "Sensor Firmware Details" : "センサーファームウェアの詳細",
    "cumulative type" : "累積型",
    "date" : "日付",
    "comments" : "コメント",
    "Test Start Date" : "テスト開始日",
    "Test End Date" : "テスト終了日",
    "Tester" : "テスター",
    "Transmission Frequency" : "送信周波数",
    "Firmware Version Date" : "ファームウェアのバージョンの日付",
    "Videos" : "動画",
    "Sensor Detections Summary" : "センサー検出の概要",
    "Total Detection Count" : "総検出数",
    "do you want to start the test execution" : "テスト実行を開始しますか ",
    "do you want to end the test execution" : "テストの実行を終了しますか ",
    "Test Create Date Range" : "テスト作成日範囲",
    "Edit Test Execution" : "テスト実行の編集",
    "YouTube Video URL" : "YouTube ビデオの URL",
    "temperature ºC" : "温度℃",
    "humidity %" : "湿度%",
    "min value -30 & max value 100" : "最小値 -30 & 最大値 100",
    "min value 0 & max value 100" : "最小値 0 & 最大値 100",
    "max 500 chars." : "最大 500 文字。",
    "Test Report" : "試験報告書",
    // "test execution" : "テストの実行",
    "Consolidated Sensor Detections Summary" : "統合センサー検出の概要",
    "Please select the sensor type to generate the report." : "レポートを生成するにはセンサーの種類を選択してください。",
    "no test reported!" : "テストが報告されていません!",
    "Transmission Frequency (mins)" : "送信頻度（分）",
    "Version Date" : "バージョンの日付",
    "Updated Date" : "更新日",
    "objective name" : "目標名",
    "objective code" : "目的のコード",
    "species name" : "種名",
    "create objective" : "目標の作成",
    "please enter objective name" : "目標名を入力してください",
    "please enter objective code" : "目的のコードを入力してください",
    "edit objective" : "目的の編集",
    "please enter species name" : "種名を入力してください",
    "Test species created successfully." : "テスト種が正常に作成されました。",
    "Test species updated successfully." : "テスト種が正常に更新されました。",
    "Test species delete successfully." : "テスト種の削除に成功しました。",
    "Test objective created successfully." : "テスト目標が正常に作成されました。",
    "Test objective updated successfully." : "テスト目標が正常に更新されました。",
    "Test objective delete successfully." : "テスト目標の削除に成功しました。",
    "firmware versions" : "ファームウェアのバージョン",
    "Create Firmware Version" : "ファームウェアバージョンの作成",
    "Comments must be between 2 and 100 chars." : "コメントは 2 文字から 100 文字まででなければなりません。",
    "Please enter firmware version" : "ファームウェアのバージョンを入力してください",
    "Please enter transmission frequency (mins)" : "送信頻度（分）を入力してください",
    "Test Firmware Version created successfully." : "テストファームウェアバージョンが正常に作成されました。",
    "Test Firmware Version updated successfully." : "テストファームウェアバージョンが正常に更新されました。",
    "Test firmware version deleted successfully." : "テストファームウェアバージョンが正常に削除されました。",
    "Edit Firmware Version" : "ファームウェアバージョンの編集",
    "objectives" : "目的",
    "firmware version details" : "ファームウェアバージョンの詳細"
}