export const spanishTranslate = {
    "dashboard": "Panel de control",
    "sensors": "Sensores",
    "users": "Usuarios",
    "customers": "Clientes",
    "reports": "Informes",
    "total": "Total",
    "settings": "Ajustes",
    "all": "Todo",
    "sensor id": "ID del sensor",
    "customer": "Cliente",
    "site": "Sitio",
    "site details": "Datos del sitio",
    "zone": "Zona",
    "zone details": "Datos de la zona",
    "close": "Cerrar",
    "scanner": "Escáner",
    "scan": "Escanear",
    "sensor type": "Tipo de sensor",
    "add sensor": "Agregar sensor",
    "sensor name": "Nombre del sensor",
    "zone map": "Mapa de zona",
    "add user": "Añadir usuario",
    "all users": "Todos los usuarios",
    "superadmin": "Superadministrador",
    "regional manager": "Administrador regional",
    "branch manager": "Administrador de división",
    "service technician": "Técnico de servicio",
    "custom users": "Usuarios personalizados",
    "username": "Nombre de usuario",
    "email": "Correo electrónico",
    "first name": "Nombre",
    "last name": "Apellidos",
    "role": "Rol",
    "actions": "Acciones",
    "my profile": "Mi perfil",
    "edit user": "Editar usuario",
    "update": "Actualizar",
    "please enter your username": "Introduzca su nombre de usuario",
    "please enter site name": "Introduzca el nombre del sitio",
    "please enter your email": "Introduzca su correo electrónico",
    "please enter valid email": "Introduzca un correo electrónico válido",
    "please enter your password": "Introduzca su contraseña",
    "please confirm your password": "Confirme su contraseña",
    "passwords do not match": "Las contraseñas no coinciden",
    "please enter your first name": "Introduzca su nombre",
    "please enter your last name": "Introduzca sus apellidos",
    "please select language": "Seleccione el idioma",
    "please enter your timezone": "Introduzca su zona horaria",
    "please enter your phone no.": "Introduzca su número de teléfono.",
    "please enter valid phone no.": "Introduzca un número de teléfono válido",
    "please enter your city": "Introduzca su ciudad",
    "please enter your state": "Introduzca su estado",
    "please enter your country": "Introduzca su país",
    "please enter your address": "Introduzca su dirección",
    "please enter your zip code/postal code": "Introduzca su código postal",
    "role name": "Nombre del rol",
    "last updated at": "Última actualización a las",
    "create customer": "Crear cliente",
    "resources": "Recursos",
    "sites": "Sitios",
    "zones": "Zonas",
    "address": "Dirección",
    "country": "País",
    "state": "Estado",
    "city": "Ciudad ",
    "zip code/postal code": "Código postal",
    "contact person": "Persona de contacto",
    "upload photo": "Cargar foto",
    "details": "Datos",
    "user details": "Datos del usuario",
    "enter user details": "Introduzca los datos del usuario",
    "offline": "Desconectado",
    "rodent": "Roedor",
    "glue board": "Placa adhesiva",
    "flying insect": "Insecto volador",
    "fly" : "Insecto volador",
    "Crawling Insect": "Insecto rastrero",
    "crawling insect": "Insecto rastrero",
    "add site": "Añadir sitio",
    "site name": "Nombre del sitio",
    "select site type": "Seleccionar tipo de sitio",
    "air quality": "Calidad del aire",
    "zone list": "Lista de zonas",
    "zone name": "Nombre de zona",
    "select site": "Seleccionar sitio",
    "edit profile": "Editar perfil",
    "edit theme": "Editar tema",
    "change password": "Cambiar contraseña",
    "new password": "Nueva contraseña",
    "confirm new password": "Confirmar nueva contraseña",
    "sign out": "Cerrar sesión",
    "sign in": "Iniciar sesión",
    "enter username": "Introducir nombre de usuario",
    "enter password": "Introducir contraseña",
    "password": "Contraseña",
    "remember me": "Recordarme",
    "forgot password?": "¿Has olvidado tu contraseña?",
    "download csv file": "Descargar archivo CSV",
    "download pdf file": "Descargar archivo PDF",
    "download png file": "Descargar archivo PNG",
    "pest infestation": "Infestación de plagas",
    "notification": "Notificación",
    "timeseries": "Series temporales",
    "add action": "Añadir acción",
    "online": "En línea",
    "select customer": "Seleccionar cliente",
    "select zone": "Seleccionar zona",
    "please choose a excel file": "Elija un archivo Excel",
    "ok": "Aceptar",
    "clear": "Borrar",
    "delete": "Eliminar",
    "re-assign sensor": "Reasignar sensor",
    "edit sensor": "Editar sensor",
    "update sensor": "Actualizar sensor",
    "sensor details": "Datos del sensor",
    "sensor unavailable": "Sensor no disponible",
    "sensor id unavailable": "ID de sensor no disponible",
    "sensor installed": "Sensor instalado",
    "re assign sensor": "Reasignar sensor",
    "re assigned successfully": "Reasignado correctamente",
    "directional chart": "Gráfico direccional",
    "no sensor available": "Ningún sensor disponible",
    "to add a sensor.": "Para añadir un sensor.",
    "select pest type": "Seleccionar tipo de plaga",
    "customer-site time series comparison": "Comparación de series temporales cliente-sitio",
    "hour": "Hora",
    "week": "Semana",
    "month": "Mes",
    "year": "Año",
    "notifications": "Notificaciones",
    "select sensor": "Seleccionar sensor",
    "enable": "Activar",
    "disable": "Desactivar",
    "user successfully created.": "Usuario creado correctamente.",
    "user successfully updated.": "Usuario actualizado correctamente.",
    "customer successfully created.": "Cliente creado correctamente.",
    "show customer list": "Mostrar lista de clientes",
    "individual customer": "Cliente individual",
    "site successfully created.": "Sitio creado correctamente.",
    "show site list": "Mostrar lista de sitios",
    "show zone list": "Mostrar lista de zonas",
    "choose  a activity category": "Seleccionar una categoría de actividad",
    "processing steps": "Pasos de procesamiento",
    "assigned successfully": "Asignado exitosamente",
    "yes": "SÍ",
    "no": "NO",
    "updated successfully": "Actualizado correctamente",
    "please choose a image file": "Seleccione un archivo de imagen",
    "no user available": "Ningún usuario disponible",
    "click here": "Haga clic aquí",
    "Clone Role": "Clonar rol",
    "edit customer": "Editar cliente",
    "edit site": "Editar sitio",
    "day": "Día",
    "today": "Hoy",
    "deleted successfully": "Eliminado correctamente",
    "created successfully": "Creado correctamente",
    "edited successfully": "Editado correctamente",
    "password changed successfully.": "Contraseña cambiada correctamente.",
    "site successfully updated.": "Sitio actualizado correctamente.",
    "customer successfully updated.": "Cliente actualizado correctamente.",
    "select all": "Seleccionar todo",
    "collapse": "Contraer",
    "expand": "Expandir",
    "select language": "Seleccionar idioma",
    "direction": "Dirección",
    "sensor": "Sensor",
    "hour of the day": "Hora del dia",
    "select timezone": "Selecciona la zona horaria",
    "roles": "Roles",
    "total count": "Recuento total",
    "from": "De",
    "to": "Para",
    "no.": "N.º",
    "stop scan": "Detener escaneo",
    "aqi": "AQI",
    "at": "a las",
    "edit role": "Editar rol",
    "updated by": "Actualizado por",
    "updated at": "Actualizado a las",
    "created by": "Creado por",
    "successfully sensor installed": "Sensor instalado correctamente",
    "sensor updated successfully": "Sensor actualizado correctamente",
    "custom role created successfully": "Rol personalizado creado correctamente",
    "custom role edited successfully": "Rol personalizado editado correctamente",
    "Sensor already scanned": "Sensor ya escaneado",
    "file formats should be JPG/JPEG/PNG": "Los formatos de archivo deben ser JPG/JPEG/PNG",
    "sensor(s) in the list were added successfully": "El sensor o sensores de la lista se han añadido correctamente",
    "enter your pincode": "Introduzca su código PIN",
    "enter your country": "Introduzca su país",
    "enter your state": "Introduzca su estado",
    "enter your city": "Introduzca su ciudad",
    "enter your street": "Introduzca su calle",
    "please select your timezone": "Seleccione su zona horaria",
    "please enter zone name": "Introduzca el nombre de la zona",
    "please select role": "Seleccione el rol",
    "please enter your customer name": "Introduzca el nombre de su cliente",
    "please enter your contact person": "Introduzca su persona de contacto",
    "pest type": "Tipo de plaga",
    "last updated": "Última actualización",
    "please enter your current password": "Introduzca su contraseña actual",
    "Passwords must be between 6 and 12 characters and contain at least two of the following: uppercase letters, lowercase letters, numbers, and symbols.": "Las contraseñas deben tener entre 6 y 12 caracteres e incluir al menos dos de los siguientes elementos: letras mayúsculas, letras minúsculas, números y símbolos.",
    "please select customer (optional)": "Seleccione el cliente (opcional)",
    "sensor error": "Error del sensor",
    "please choose customer": "Seleccione el cliente",
    "please choose site": "Seleccione el sitio",
    "please choose zone": "Seleccione la zona",
    "please enter sensor name": "Introduzca el nombre del sensor",
    "please choose sensor type": "Seleccione el tipo de sensor",
    "no users available.": "no hay usuarios disponibles.",
    "rodent sensor": "Sensor de roedores",
    "please enter sensor id": "Introduzca el ID del sensor",
    "sensor already installed": "Sensor ya instalado",
    "deployment type": "Tipo de instalación",
    "max length 200": "Longitud máx. 200",
    "max length 20": "Longitud máx. 20",
    "sensor name must be a minimum of 6 characters": "El nombre del sensor debe tener un mínimo de 6 caracteres",
    // "sensor name cannot be blank": "El nombre del sensor no puede estar en blanco",
    "max length 8": "Longitud máx 8",
    "cancel": "Cancelar",
    "qr code scan": "Escaneo de código QR",
    "file size should be less than 5 MB": "El tamaño del archivo debe ser inferior a 5 MB.",
    "successfully uninstalled the sensor": "Sensor desinstalado correctamente",
    "successfully reassigned to selected customer": "Reasignado correctamente al cliente seleccionado",
    "complete installation": "Instalación completada",
    "This Sensor is not currently active on the IoP system and cannot be used.": "Este sensor no está activo actualmente en el sistema IoP y no se puede utilizar.",
    "Invalid sensor ID! This sensor is already assigned elsewhere.": "¡ID de sensor no válido! Este sensor ya está asignado a otro lugar.",
    "This sensor is already installed on your account.": "Este sensor ya está instalado en su cuenta.",
    "This sensor is already added to your account.": "Este sensor ya está añadido a su cuenta.",
    "This sensor is has already been installed on the IoP system.": "Este sensor ya se ha instalado en el sistema IoP.",
    "Invalid sensor ID specified! Please enter a valid sensor ID for installation.": "¡ID de sensor no válido! Introduzca una identificación de sensor válida para la instalación.",
    "error during get sensor metadata details : sensorId not available": "Error al obtener detalles de metadatos del sensor: ID de sensor no disponible",
    "no whitespace at the beginning and end": "Ningún espacio en blanco al principio y al final",
    "date & time": "Fecha y hora",
    "manage roles": "Administrar roles",
    "reset": "Reiniciar",
    "choose a reporting manager": "Seleccionar un administrador",
    "organisation": "Organización",
    "create new custom role": "Crear nuevo rol personalizado",
    "edit custom role": "Editar rol personalizado",
    "parent role": "Rol principal",
    "enter new custom role name": "Introducir nuevo nombre de rol personalizado",
    "please enter custom role name": "Introduzca el nombre del rol personalizado",
    "please enter different custom role name": "Introduzca un nombre de rol personalizado diferente",
    "enter the role description": "Introduzca la descripción del rol",
    "no role available": "No hay rol disponible",
    "permission": "Permiso",
    "create": "Crear",
    "view": "Ver",
    "please enter a valid mobile number": "Introduzca un número de móvil válido",
    "clone": "Clon",
    "description": "Descripción",
    "delete role": "Eliminar rol",
    "max length 225": "Longitud máxi 225",
    "re-enter password": "Volver a introducir contraseña",
    "please enter re-enter password": "Vuelva a introducir la contraseña",
    "name": "Nombre",
    "reporting manager": "Administrador",
    "update user": "Actualizar usuario",
    "timezone": "Zona horaria",
    "click to enable": "Hacer clic para activar",
    "click to disable": "Hacer clic para desactivar",
    "account overview": "Resumen de cuenta",
    "reporting users": "Usuarios administrados",
    "detections": "Detecciones",
    "sensors overview": "Resumen de los sensores",
    "view all": "Ver todo",
    "pest detections": "Detección de plagas",
    "all sensors": "Todos los sensores",
    "new": "Nuevo",
    "active": "Activo",
    "idle": "Inactivo",
    "home": "Inicio",
    "glue board details": "Datos de la placa adhesiva",
    "added by": "Añadido por",
    "added on": "Añadido el",
    "location description": "Descripción de la ubicación",
    "sensor pictures": "Imágenes del sensor",
    "glue board id": "ID de placa adhesiva",
    "24 hrs": "24 horas",
    "12 hrs": "12 horas",
    "6 hrs": "6 horas",
    "1 hr": "1 hora",
    "1 week": "1 semana",
    "1 month": "1 mes",
    "lifetime": "Vida útil",
    "remove sensor": "Eliminar sensor",
    "What is the reason for removing this sensor?": "¿Cuál es el motivo para eliminar este sensor?",
    "sensor damaged": "Sensor dañado",
    "other reason": "Otro motivo",
    "Yes, I confirm this sensor to be permanently removed": "Sí, confirmo la eliminación permanente de este sensor.",
    "Enter Sensor ID or Scan QR code": "Introduzca el ID del sensor o escanee el código QR",
    "Enter Sensor name": "Introduzca el nombre del sensor",
    "select deployment type": "Seleccionar tipo de instalación",
    "enter sensor location description": "Introduzca la descripción de la ubicación del sensor",
    "sensor location description": "Descripción de la ubicación del sensor",
    "Describe the placement of the sensor in words.": "Describe la ubicación del sensor en palabras.",
    "add sensors": "Añadir sensores",
    "basic details": "Datos básicos",
    "select sensors": "Seleccionar sensores",
    "review & complete": "Revisar y completar",
    "number of sensors to be added": "Número de sensores para añadir",
    "please choose number of sensors": "Seleccione el número de sensores",
    "back": "Atrás",
    "save & continue": "Guardar y Continuar",
    "I confirm above sensors to be added to my account.": "Confirmo la adición de los sensores anteriores a mi cuenta.",
    "max length 255": "Longitud máx 255",
    "please choose pest type": "Seleccione el tipo de plaga",
    "last seen": "Visto por ultima vez",
    "Unavailable": "No disponible",
    "comm status": "Estado de comunicación",
    "Comm Status": "Estado de comunicación",
    "sensor images": "Imágenes del sensor",
    "proceed": "Continuar",
    "are you sure want to cancel it ?": "¿Seguro que desea cancelarlo?",
    "weeks": "Semanas",
    "days": "Dias",
    "hours": "Horas",
    "customer information": "Información al cliente",
    "Enter Customer Name": "Introducir nombre del cliente",
    "contact person details": "Datos de la persona de contacto",
    "I confirm to add this new customer to my account": "Confirmo la adición de este nuevo cliente a mi cuenta",
    "Update Avatar": "Actualizar avatar",
    "Enter Email ID": "Introducir ID de correo electrónico",
    "enter phone number": "Introducir número telefónico",
    "enter contact person name": "Introducir nombre de la persona de contacto",
    "all customers": "Todos los clientes",
    "all sites": "Todos los sitios",
    "all zones": "Todas las zonas",
    "customer, site, zone": "Cliente, sitio, zona",
    "manage sites": "Administrar sitios",
    "postal code": "Código postal",
    "phone": "Teléfono",
    "enter address": "Introducir la direccion",
    "phone number": "Número de teléfono",
    "email id": "ID de correo electrónico",
    "customer address": "Dirección del cliente",
    "Manage Sites and Zones": "Administrar sitios y zonas",
    "contact person name": "Nombre de la persona de Contacto",
    "I confirm to add this new customer to my account, and to all the reporting managers above me.": "Confirmo haber añadido a este nuevo cliente a mi cuenta y a todos los administradores con un cargo superior.",
    "This role name is already taken. Please try a different one!": "Este nombre de función ya existe. ¡Pruebe con uno diferente!",
    "I confirm to add the above sensors to my account, and to all the managers of this site.": "Confirmo haber añadido los sensores anteriores a mi cuenta y a todos los administradores de este sitio.",
    "please select the checkbox": "Seleccione la casilla de verificación",
    "remove customer": "Eliminar cliente",
    "customer removed successfully": "Cliente eliminado correctamente",
    "please enter a valid sensor id": "Introduzca un ID de sensor válido",
    "This site name is already taken. Please try a different one!": "Este nombre de sitio ya existe. ¡Prueba con uno diferente!",
    "This zone name is already taken. Please try a different one!": "Este nombre de zona ya existe. ¡Prueba con uno diferente!",
    "This sensor name is already taken. Please try a different one!": "Este nombre de sensor ya existe. ¡Prueba con uno diferente!",
    "sensor name already added": "Nombre de sensor ya añadido",
    "24 Hours": "24 horas",
    "This customer name is already taken. Please try a different one!": "Este nombre de cliente ya existe. ¡Prueba con uno diferente!",
    "resolved": "Resuelto",
    "resolve": "Resolver",
    "Recent Detection": "Detección reciente",
    "detection log": "Registro de detección",
    "time stamp": "Fecha y hora",
    "detection details": "Datos de detección",
    "new detection": "Nueva detección",
    "new detections": "Nuevas detecciones",
    "no detections": "No hay detecciones",
    "Proof of Detection Resolution": "Prueba de resolución de detección",
    "I have completed the pest management and sensor services required to resolve this detection.": "He completado los servicios de control de plagas y de sensores necesarios para resolver esta detección.",
    "Did you find any evidence of pest presence?": "¿Ha encontrado alguna evidencia de presencia de plagas?",
    "Successfully resolved activity": "Actividad resuelta correctamente",
    "Resolution Information": "Información de resolución",
    "Resolution Number": "Número de resolución",
    "resolved by": "Resuelto por",
    "resolved on": "Resuelto el",
    "Date": "Fecha",
    "Time": "Hora",
    "view location": "Ver ubicación",
    "Detection has been successfully resolved.": "La detección se ha resuelto correctamente.",
    "sensor is malfunctioning": "El sensor está averiado",
    "sensor is no longer required": "El sensor ya no es necesario",
    "This sensor will be marked as malfunctioning and removed permanently. The removed sensor cannot be reused.": "Este sensor se marcará como averiado y se eliminará permanentemente. El sensor retirado no se puede reutilizar.",
    "This sensor will be removed and can be later added back as required.": "Este sensor se eliminará y se podrá volver a añadir según sea necesario.",
    "I confirm the permanent removal of this sensor.": "Confirmo la eliminación permanente de este sensor.",
    "I confirm the removal of this sensor.": "Confirmo la eliminación de este sensor.",
    "The sensor has been permanently removed": "El sensor se ha eliminado permanentemente",
    "The sensor has been successfully removed": "El sensor se ha eliminado correctamente",
    "date range": "Rango de fechas",
    "no Glue board details": "No hay datos de placa adhesiva",
    "replace sensor": "Sustituir sensor",
    "basic information": "Información básica",
    "sensor replacement": "Sustitución del sensor",
    "Glue board detection breach limit": "Límite de infracción de detección de placa adhesiva",
    "Glue board duration breach limit": "Límite de infracción de la duración del tablero adhesivo",
    "Communication status": "Estado de comunicación",
    "pictures": "Imágenes",
    "What is the reason for replacing this sensor?": "¿Cuál es el motivo para sustituir este sensor?",
    "Sensor malfunctioning": "Sensor averiado",
    "Sensor Damaged": "Sensor dañado",
    "Sensor Lost": "Sensor perdido",
    "Technician can give the statement of the existing sensor condition.": "El técnico puede informar del estado del sensor existente.",
    "Please describe below": "Descríbalo a continuación",
    "confirm & replace": "Confirmar y sustituir",
    "enter the new sensor details": "Introduzca los datos del nuevo sensor",
    "I confirm the addition of the new sensor in place of the sensor selected for replacement. This action cannot be undone.": "Confirmo la adición del nuevo sensor en lugar del sensor seleccionado para la sustitución. Esta acción no se puede deshacer.",
    "The sensor has been replaced successfully!": "¡El sensor se ha sustituido correctamente!",
    "View Sensor list": "Ver lista de sensores",
    "breach limits": "Límites de infracción",
    "Default No. of days for the glueboard expiry": "N.º predeterminado de días hasta la caducidad de la placa adhesiva",
    "Default max fly detections allowed on the glueboard": "Detecciones máx. predeterminadas de insectos voladores permitidas en la placa adhesiva",
    "please enter No. of days for the glueboard expiry": "Introduzca el n.º de días hasta la caducidad de la placa adhesiva",
    "please enter max flying insect detections allowed on the glue board": "Introduzca las detecciones máx. de insectos voladores permitidas en la placa adhesiva.",
    "Default fly detections limit": "Límite predeterminado de detecciones de insectos voladores",
    "Default Rodent detections limit": "Límite predeterminado de detecciones de roedores",
    "Please Enter Flying Insect Critical Control Limit": "Introduzca el límite de control crítico de insectos voladores",
    "Please Enter Crawling Insect Critical Control Limit": "Introduzca el límite crítico de control de insectos rastreros",
    "Please Enter Rodent Critical Control Limit": "Introduzca el límite de control crítico de roedores",
    "language": "Idioma",
    "No. of days for the glueboard expiry": "N.º de días restantes hasta la caducidad de la placa adhesiva",
    "max fly detections allowed on the glueboard": "Detecciones máx. de insectos voladores permitidas en la placa adhesiva",
    "Default No. of days for the glue board expiry between 1 to 90": "N.º de días predeterminado hasta la caducidad de la placa adhesiva entre 1 y 90",
    "Default max flying insect detections allowed on the glue board between 1 to 1999": "Detecciones máx. predeterminadas de insectos voladores permitidas en la placa adhesiva entre 1 y 1999",
    "Default flying insect detections limit between 0 to 1999": "Límite predeterminado de detecciones de insectos voladores entre 0 y 1999",
    "Default Crawling Insect detections limit between 0 to 1999": "Límite predeterminado de detecciones de insectos rastreros entre 0 y 1999",
    "Default Rodent detections limit between 0 to 1999": "Límite predeterminado de detecciones de roedores entre 0 y 1999",
    "This sensor will be marked as malfunctioning and removed permanently. The replaced sensor cannot be reused.": "Este sensor se marcará como averiado y se eliminará permanentemente. El sensor sustituido no se puede reutilizar.",
    "max fly detections allowed on the glueboard between 1 to 1999": "Detecciones máx. de insectos voladores permitidas en la placa adhesiva entre 1 y 1999",
    "No. of days for the glue board expiry between 1 to 90": "N.º de días hasta la caducidad de la placa adhesiva entre 1 y 90",
    "new sensors": "Nuevos sensores",
    "active sensors": "Sensores activos",
    "idle sensors": "Sensores inactivos",
    "offline sensors": "Sensores fuera de línea",
    "breach": "Infracción",
    "to create a customer": "Crear un cliente",
    "Replacing sensor should be of the same pest type.": "El sensor de sustitución debe ser del mismo tipo de plaga.",
    "site type": "Tipo de sitio",
    "no customer available.": "Ningún cliente disponible.",
    "no site available": "Ningún sitio disponible",
    "site event log": "Registro de eventos del sitio",
    "add site & zone": "Añadir sitio y zona",
    "add more": "Añadir más",
    "enter state": "Introducir estado",
    "Enter zip code/postal code": "Introducir código postal",
    "enter site name": "Introducir nombre del sitio",
    "enter city": "Introducir ciudad",
    "enter country": "Introducir país",
    "service team": "Equipo de servicio",
    "user name": "Nombre de usuario",
    "I confirm the addition of this new site to my account, service team and all the reporting managers.": "Confirmo la adición de este nuevo sitio a mi cuenta, al equipo de servicio y a todos los administradores.",
    "please select timezone": "Seleccione la zona horaria",
    "site information": "Información del sitio",
    "edit site & zone": "Editar sitio y zona",
    "Flying Insect Glue Board Settings": "Ajustes de la placa adhesiva de insectos voladores",
    "Critical Control Limits": "Límites de control críticos",
    "Default Flying Insect Critical Control Limit of the Zone": "Límite predeterminado de control crítico de insectos voladores de la zona",
    "Default Crawling Insect Critical Control Limit of the Zone": "Límite predeterminado de control crítico de insectos rastreros de la zona",
    "Default Rodent Critical Control Limit of the Zone": "Límite predeterminado de control crítico de roedores de la zona",
    "resolved detections": "Detecciones resueltas",
    "is Breached on": "se ha infringido el",
    "is Breached at": "se ha infringido a las",
    "Flying Insect Glue Board Detections": "Detecciones de placa adhesiva de insectos voladores",
    "Zone Rodent Critical Control Limit": "Límite de control crítico de roedores de la zona",
    "Zone Fly Critical Control Limit": "Límite de control crítico de insectos voladores de zona",
    "Zone Crawling Insect Critical Control Limit": "Límite de control crítico de rastreros de la zona",
    "manage zones": "Administrar zonas",
    "breach limit": "Límite de infracción",
    "click": "Hacer clic",
    "This service team member is already taken. Please try a different one!": "Este miembro del equipo de servicio ya está asignado. ¡Seleccione otro miembro!",
    "add team member": "Agregar miembro del equipo",
    "no service team available": "Ningún equipo de servicio disponible",
    "to add team member": "Para agregar un miembro del equipo",
    "revoke access": "Revocar acceso",
    "updated on": "Actualizado el",
    "add member": "Añadir miembro",
    "number of sites assigned": "Número de sitios asignados",
    "select user": "Seleccionar usuario",
    "please select user": "Seleccione usuario",
    "zone detection resolution": "Resolución de detección de la zona",
    "action": "Acción",
    "site detection resolution": "Resolución de detección del sitio",
    "I have completed the pest management and sensor services required to resolve the new detections in this site.": "He completado los servicios de control de plagas y de sensores necesarios para resolver las nuevas detecciones en este sitio.",
    "Sensor Detection Resolution": "Resolución de detección del sensor",
    "Grant Access": "Conceder acceso",
    "Add team member to the site": "Añadir miembro del equipo al sitio",
    "I confirm granting site access to this member and to all the reporting managers.": "Confirmo que concedo acceso al sitio a este miembro y a todos los administradores.",
    "has been granted access to the selected site": "se ha concedido acceso al sitio seleccionado",
    "Remove team member from the site": "Eliminar miembro del equipo del sitio",
    "I confirm revoking site access from this member.": "Confirmo revocar el acceso al sitio de este miembro.",
    "has been revoked access to the selected site": "se ha revocado el acceso al sitio seleccionado",
    "detected": "Detectado",
    "notifications center": "Centro de notificaciones",
    "Edit Service Team Members for the site by changing users below": "Edite los miembros del equipo de servicio para el sitio cambiando los siguientes usuarios",
    "Add Service Team Members for the site by choosing users below": "Añada miembros del equipo de servicio para el sitio seleccionando los siguientes usuarios",
    "Please use a different username": "Utilice un nombre de usuario diferente",
    "is": "es",
    "edit": "Editar",
    "replace": "Sustituir",
    "remove": "Eliminar",
    "Zone Information": "Información de la zona",
    "User": "Usuario",
    "important notifications": "Notificaciones importantes",
    "confirmation": "Confirmación",
    "Are you sure you want to mark all the new notifications as read?": "¿Seguro que desea marcar todas las notificaciones nuevas como leídas?",
    "Yes, Proceed": "Si, continuar",
    "The new notifications have been successfully cleared. They can still be accessed from the Notifications Center.": "Las nuevas notificaciones se han borrado correctamente. Todavía son accesibles desde el centro de notificaciones.",
    "Site Detections Resolved by": "Detecciones del sitios resueltas por",
    "Zone Detections Resolved by": "Detecciones de la zona resueltas por",
    "New Flying Insect Detections at Site": "Nuevas detecciones de insectos voladores en el sitio",
    "New Crawling Insect Detections at Site": "Nuevas detecciones de insectos rastreros en el sitio",
    "New Rodent Detections at Site": "Nuevas detecciones de roedores en el sitio",
    "Flying Insect Sensors": "Sensores de insectos voladores",
    "Rodent Sensors": "Sensores de roedores",
    "zone detections resolved": "Detecciones de zona resueltas",
    "site detections resolved": "Detecciones de sitios resueltas",
    "by": "por",
    "is Breached!": "¡se ha infringido!",
    "Breached!": "¡Infringido!",
    "All Notifications": "Todas las notificaciones",
    "Breach Alerts": "Alertas de infracción",
    "Site Events": "Eventos del sitio",
    "Sensor Events": "Eventos del sensor",
    "Mark all as read": "Marcar todo como leido",
    "I have completed the pest management and sensor services required to resolve the new detections in this zone.": "He completado los servicios de control de plagas y de sensores necesarios para resolver las nuevas detecciones en esta zona.",
    "total sensors": "Total de sensores",
    "IoP® Dashboard": "Panel de control de IoP®",
    "Flying Insect Detections": "Detecciones de insectos voladores",
    "Rodent Detections": "Detecciones de roedores",
    "Fly Detection": "Detección de insectos voladores",
    "Rodent Detection": "Deteccion de roedores",
    "Fly Trend": "Tendencia de los insectos voladores",
    "Rodent Trend": "Tendencia de roedores",
    "New Detections by Pest Type": "Nuevas detecciones por tipo de plaga",
    "Detections - New vs Resolved": "Detecciones: nuevas frente a resueltas",
    "View All New Detections": "Ver todas las nuevas detecciones",
    "Sensors grouped by Pest Type": "Sensores agrupados por tipo de plaga",
    "View All Sensors": "Ver todos los sensores",
    "Sensors grouped by Communication Status": "Sensores agrupados por estado de comunicación",
    "© 2024 Evoscien. All Rights Reserved.": "© 2024 Evoscien. Todos los derechos reservados.",
    "There are": "Hay",
    "more new notifications,": "Más notificaciones nuevas,",
    "detections overview": "Resumen detecciones",
    "Fly Sensor Detections Resolved by": "Detecciones de sensores de insectos voladores resueltas por",
    "Fly Sensor Detections Resolved": "Detecciones de sensores de insectos voladores resueltas",
    "New Fly Detections": "Nuevas detecciones de insectos voladores",
    "New Rodent Detections": "Nuevas detecciones de roedores",
    "create site": "Crear sitio",
    "to create a site.": "para crear un sitio.",
    "Add Zone Map": "Añadir mapa de zona",
    "please enter the role description": "Introduzca la descripción del rol",
    "Sensor Detections Resolved by": "Detecciones de sensor resueltas por",
    "sensor detections resolved": "Detecciones de sensor resueltas",
    "current password": "Contraseña Actual",
    "reset password": "Restablecer contraseña",
    "Additional Comments": "Comentarios adicionales",
    "Do you want to resolve the detections on this site?": "¿Desea resolver las detecciones en este sitio?",
    "Do you want to resolve the detections on this sensor?": "¿Desea resolver las detecciones en este sensor?",
    "Do you want to resolve the detections on this zone?": "¿Desea resolver las detecciones en esta zona?",
    "Resolve": "Resolver",
    "Cancel": "Cancelar",
    "pest infestation summary": "Resumen de infestación de plagas",
    "time series comparison": "Comparación de series temporales",
    "Time Series": "Series de tiempo",
    "Cumulative Sum": "Suma acumulada",
    "Hours Distribution": "Distribución de Horas",
    "Percentage": "Porcentaje",
    "Trend is unavailable for the current selection criteria.": "Tendencia no disponible para los criterios de selección actuales.",
    "Data Aggregation By": "Datos añadidos por",
    "Detections Report": "Informe de detecciones",
    "- Create Customer -": "- Crear cliente -",
    "Critical Control Limit": "Límite de control crítico",
    "max length 12": "Longitud máx. 12",
    "create user": "Crear usuario",
    "to create a user.": "Para crear un usuario.",
    "pest detections report": "Informe de detecciones de plagas",
    "all crawling insect": "Todo los insecto rastrero",
    "all rodents": "Todos los roedores",
    "all crawling insect sensors": "Todos los sensores de insectos rastreros",
    "all rodent sensors": "Todos los sensores para roedores",
    "all fly sensors": "Todos los sensores de insectos voladores",
    "Crawling Insect Critical Control Limit": "Límite de control de insectos rastreros",
    "Fly Critical Control Limit": "Límite de control crítico de insectos voladores",
    "Rodent Critical Control Limit": "Límite de control crítico de roedores",
    "max length 24": "Longitud máx. 24",
    "min length 6": "Longitud mín. 6",
    "year I": "Año I",
    "year II": "Año II",
    "week I": "Semana I",
    "week II": "Semana II",
    "month I": "Mes I",
    "month II": "Mes II",
    "day I": "Día I",
    "day II": "Día II",
    "comparison type": "Tipo de comparación",
    "Max Custom Date Range selection is 90 days at a stretch": "La selección máx. del rango de fechas personalizado es de 90 días seguidos",
    "Crawling Insect Cumulative Sum": "Suma acumulativa de insectos rastreros",
    "Rodent Cumulative Sum": "Suma acumulada de roedores",
    "Flying Insect Cumulative Sum": "Suma acumulada de insectos voladores",
    "glue board expiry": "Caducidad de la placa adhesiva",
    "Wi-Fi": "Wi-Fi",
    "max allowed on glue board": "máximo permitido en tablero adhesivo",
    "please select upto current month": "Seleccione hasta el mes actual",
    "events log": "Registro de eventos",
    "event": "Evento",
    "month of the year": "Mes del año",
    "day of the month": "Día del mes",
    "day of the week": "Día de la semana",
    "Sensor Management": "Administración de sensores",
    "LPWAN": "LPWAN",
    "all detections": "Todas las detecciones",
    "Glue Trap": "Trampa adhesiva",
    "Standalone": "Autónomo",
    "Bait Station": "Estación de cebo",
    "max": "Max",
    "years": "Años",
    "hr": "hora",
    "hrs": "horas",
    "min": "min",
    "mins": "minutos",
    "sec": "segundo",
    "secs": "segundos",
    "ago": "atrás",
    "days left": "quedan días",
    "day left": "queda 1 día",
    "Expired": "Caducado",
    "days ago": "hace dias",
    "day ago": "hace 1 dia",
    "Entry from Left Side": "Entrada desde el lado izquierdo",
    "Entry from Right side": "Entrada por el lado derecho",
    "Values denote the rodent's direction of entry on the sensor": "Los valores indican la dirección de entrada del roedor en el sensor.",
    "Values denote the side of entry of crawling insect on the sensor": "Los valores indican el lado de entrada del insecto rastrero en el sensor",
    "Due to variations in size, the side of entry for": "Debido a las variaciones de tamaño, la dirección de entrada de",
    "some rodent detections remains unidentified.": "algunas detecciones de roedores sigue sin identificar.",
    "events": "Eventos",
    "added": "Añadido",
    "replaced": "Sustituido",
    "removed": "Eliminado",
    "sensor idle": "Sensor inactivo",
    "sensor active": "Sensor activo",
    "sensor offline": "Sensor fuera de línea",
    "zone critical control limit breached": "Límite de control crítico de la zona superado",
    "flying insect max detections breached": "Se infringen las detecciones máx. de insectos voladores",
    "flying insect glue board expiry breached": "Caducidad de placa adhesiva de insectos voladores superada",
    "sensor established connection": "Conexión establecida con el sensor",
    "wifi configuration successful": "Configuración de Wi-Fi correcta",
    "flying insect glue board replaced": "Placa adhesiva de insectos voladores sustituida",
    "sensor name edited": "Nombre del sensor editado",
    "sensor pictures edited": "Imágenes del sensor editadas",
    "location description edited": "Descripción de la ubicación editada",
    "Detections between": "Detecciones entre",
    "in": "en",
    "Hrs": "horas",
    "on": "en",
    "during": "durante",
    "between": "Entre",
    "hrs on": "horas el",
    "detection": "Detección",
    "Day vs Day": "Día contra Día",
    "Week vs Week": "Semana frente a Semana",
    "Month vs Month": "Mes frente a Mes",
    "Year vs Year": "Año frente a Año",
    "Educational Institution": "Institución educativa",
    "Food Processing": "Procesamiento de alimentos",
    "Home": "Vivienda",
    "Hospital": "Hospital",
    "Hotel": "Hotel",
    "Loading Bay Warehouse": "Almacén de muelle de carga",
    "Office": "Oficina",
    "Restaurant": "Restaurante",
    "Retail": "Tienda minorista",
    "Theatre": "Teatro",
    "Airport": "Aeropuerto",
    "Bar": "Bar",
    "Cafe": "Cafetería",
    "Cruise line": "Línea de cruceros",
    "Supermarket": "Supermercado",
    "Train Station": "Estación de tren",
    "glueboard expiry": "Caducidad de placa adhesiva",
    "glueboard max detections": "Detecciones máx. de placa adhesiva",
    "zone ccl breached": "Zona ccl infringida",
    "established connection": "Conexión establecida",
    "established connection wifi": "Conexión wifi establecida",
    "wifi config successful": "Configuración Wi-Fi correcta",
    "glueboard changed": "Placa adhesiva cambiada",
    "detections resolved": "Detecciones resueltas",
    "Web App": "Aplicación Web",
    "Mobile App": "Aplicación Movil",
    "sensor add": "Añadir sensor",
    "sensor edit": "Editar sensor",
    "sensor remove": "Eliminar sensor",
    "sensor replace": "Sustituir sensor",
    "Site deleted successfully.": "Sitio eliminado correctamente.",
    "Zone deleted successfully.": "Zona eliminada correctamente.",
    "Super Admin": "Superadministrador",
    "Regional Manager": "Administrador regional",
    "Branch Manager": "Administrador de división",
    "Customer Service Executive": "Ejecutiva de servicio al cliente",
    "Service Manager": "Administrador de servicio",
    "Service Technician": "Técnico de servicio",
    "Customer Representative": "Representante del cliente",
    "Super Admin is the root level access of the organisation.": "Superadministrador es el acceso a nivel raíz de la organización.",
    "Regional Manager is the managerial role with scope of multiple branches. Multiple Branch Managers can report under Regional Manager.": "Administrador regional es el rol gerencial que abarca múltiples divisiones. Los administradores de división rinden cuentas al administrador regional.",
    "Branch Manager is the managerial role for one branch. Branch manager is empowered with all functions within scope of a branch.": "Administrador de división es el rol gerencial para una división. El administrador de división tiene competencia para todas las funciones en el ámbito de una división.",
    "Customer Service Executive is the custodian of achieving customer satisfaction within a branch. One or more Customer Service Executives can manage the customers in a branch as required.": "Los ejecutivos de servicio al cliente son los responsables de garantizar la satisfacción del cliente dentro de una división Uno o más ejecutivos de atención al cliente pueden administrar los clientes en una división según sea necesario.",
    "In large branches, Service Managers manage the routing and productivity of the Service Technicians on the field. One or more Service Managers, manage technicians under them for best utilisation of their time.": "En divisiones grandes, los administradores de servicio administran los itinerarios y la productividad de los técnicos de servicio de campo. Uno o más responsables de servicio administran a los técnicos con cargos inferiores.",
    "Service Technicians (a.k.a PMP, Pest Management Personnel) are the field agents who visit the customers site to deliver the Sensor Installation, Pest Control, Sensor Malfunction and Periodic maintenance services.": "Los técnicos de servicio son los agentes de campo que visitan los sitios de los clientes para prestar los servicios de instalación de sensores, resolver la actividad de plagas y realizar el mantenimiento periódico.",
    "Customer Representatives are the site/facility managers of the customer for which the services are provided. A Customer Representative can be mapped to only one customer": "Los representantes del cliente son los administradores del sitio/de las instalaciones del cliente.",
    "site events log": "Registro de eventos del sitio",
    "Site Management": "Administración de sitios",
    "Zone Management": "Administración de zonas",
    "This sensor ID does not exist! Kindly recheck and try again.": "¡Este ID de sensor no existe! Vuelva a comprobarlo e inténtelo de nuevo.",
    "Invalid Sensor! This sensor does not belong to your organisation.": "Este sensor no pertenece a su organización.",
    "Sensor is validated successfully!": "¡El sensor se ha validado correctamente!",
    "Invalid sensor! This sensor is already added elsewhere.": "Este sensor ya se ha agregado en otro lugar.",
    "Invalid sensor! This sensor has been removed as malfunctioning and can no longer be added.": "Este sensor ha sido marcado como defectuoso y ya no se puede agregar.",
    "Invalid sensor! This sensor has been replaced as malfunctioning / damaged / lost and can no longer be added.": "¡Sensor no válido! Este sensor se reemplazó porque funciona mal/se dañó/se perdió y ya no se puede agregar.",
    "Invalid sensor! This sensor was added to site": "¡Sensor no válido! Este sensor se ha añadido al sitio",
    " zone": " a la zona",
    "and is currently in use.": "y está actualmente en uso.",
    "site created": "Sitio creado",
    "site edited": "Sitio editado",
    "service team edited": "Equipo de servicio editado",
    "zone added": "Zona añadida",
    "zone name edited": "Nombre de zona editado",
    "zone deleted": "Zona eliminada",
    "zone ccl changed rodent": "zona ccl cambiada - Roedor",
    "zone ccl changed fly": "zona CCL modificada - Insecto volador",
    "Zone Rodent critical control limit changed from": "El límite de control crítico de roedores de la zona ha cambiado de",
    "Zone Crawling Insect Critical Control Limit changed from": "El límite de control crítico de insectos rastreros de la zona ha cambiado de",
    "Zone Fly critical control limit changed from": "El límite de control crítico de insectos voladores de la zona ha cambiado de",
    " to ": " a ",
    "sensor added": "Sensor añadido",
    "delete site": "Eliminar sitio",
    "delete zone": "Eliminar Zona",
    "zone flying insect critical control limit breached": "Límite de control crítico de insectos voladores de la zona superado",
    "grouped crawling insect detections by site": "Detecciones de insectos rastreros agrupadas por sitio",
    "grouped rodent detections by site": "Detecciones de roedores agrupadas por sitio",
    "grouped fly detections by site": "Detecciones de insectos voladores agrupadas por sitio",
    "type": "Tipo",
    "Site Contact Person Name": "Nombre de la persona de contacto del sitio",
    "None Selected": "Ninguno seleccionado",
    "All Selected": "Todo seleccionado",
    "Multiple Selected": "Selección múltiple",
    "glueboard max fly detections": "Detecciones máx. de insectos voladores de placa adhesiva",
    "edited": "Editado",
    "sensor photo": "Imágenes del sensor",
    "add sensor pictures": "Añadir imágenes de sensores",
    "sensor picture(s)": "Imagen o imágenes del sensor",
    "sensor picture": "Imagen del sensor",
    "flying insect glueboard max detections": "Detecciones máx. de placa adhesiva de insectos voladores",
    "flying insect glueboard expiry": "Caducidad de la placa adhesiva de insectos voladores",
    "Sensor WiFi Configuration Successful": "Configuración Wi-Fi del sensor correcta",
    "zone crawling insect breached": "Insecto rastrero de la zona violado",
    "zone fly breached": "Insecto volador de la zona ha infringido",
    "zone rodent breached": "Roedor de la zona ha infringido",
    "flying insect glueboard max detections breached": "Detecciones máx. de placa adhesiva de insectos voladores superadas",
    "sensor edited": "Sensor editado",
    "sensor removed": "Sensor eliminado",
    "sensor replaced": "Sensor sustituido",
    "edit service team": "Editar equipo de servicio",
    "sensor established connection via wifi": "Conexión establecida del sensor a través de Wi-Fi",
    "sensor wifi configuration successful": "Configuración Wi-Fi del sensor correcta",
    "zone rodent critical control limit edited": "Límite de control crítico de roedores de la zona editado",
    "zone flying insect critical control limit edited": "Límite de control crítico de insectos voladores de la zona editado",
    "zone crawling insect critical control limit edited": "Límite crítico de control de insectos rastreros de la zona editado",
    "zone crawling insect critical control limit breached": "Límite de control crítico de insectos rastreros de la zona superado",
    "flying insect glueboard expiry changed": "Caducidad de la placa adhesiva de insectos voladores cambiada",
    "flying insect glueboard max detections changed": "Detecciones máx. de la placa de adhesiva de insectos voladores cambiada",
    "Detections Resolution": "Resolución de detecciones",
    "Flying Insect Glueboard Expiry Changed from": "Caducidad de placa adhesiva de insectos voladores cambiada de",
    "days to": "dias hasta",
    "Days": "días",
    "Flying Insect Glueboard Max Detections Changed from": "Las detecciones máx. de placa adhesiva de insectos voladores han cambiado de",
    "site information edited": "Información del sitio editada",
    "contact person details edited": "Detos de la persona de contacto editados",
    "zone edited": "Zona editada",
    "Changed from": "Ha cambiado de",
    "contact phone number": "Número de teléfono de contacto",
    "contact email id": "ID de correo electrónico de contacto",
    "zone map edited": "Mapa de zona editado",
    "Zone Name, Map Edited": "Nombre de zona editado",
    "New Password and Confirm New Password does not match": "Nueva contraseña y Confirmar nueva contraseña no coinciden",
    "You have entered invalid old password.": "Ha introducido una contraseña antigua no válida/antigua.",
    "profile": "Perfil",
    "preferences": "Preferencias",
    "New Password shoudn't be same as old password.": "La nueva contraseña no debe ser la misma que la antigua.",
    "Flying Insect Glueboard": "Placa adhesiva de insectos voladores",
    "user roles": "Roles del usuario",
    "The side of the entry for": "El lado de la entrada para",
    "rodent detections is unknown due to variations in the size of the rodent.": "las detecciones de roedores se desconoce debido a las variaciones de tamaño del roedor.",
    "Critically low, requires replacement!": "¡Críticamente bajo, requiere sustitución!",
    "total sites": "Total de sitios",
    "100% fully charged": "100 % cargado",
    "remaining": "Restante",
    "administration": "Administración",
    "created by (username)": "Creado por (nombre de usuario)",
    "reporting manager (username)": "Administrador (nombre de usuario)",
    "Tobacco Beetle Glueboard": "Placa adhesiva de escarabajos del tabaco",
    "Default max cigarette beetle detections allowed on the glueboard": "Detecciones máx. predeterminadas de escarabajos del tabaco permitidas en la placa adhesiva",
    "please enter max cigarette beetle detections allowed on the glueboard": "Introduzca las detecciones máx. de escarabajos del tabaco permitidas en la placa adhesiva",
    "Default max cigarette beetle detections allowed on the glueboard between 1 to 1999": "Detecciones máx. predeterminadas de escarabajos del tabaco permitidas en la placa adhesiva (de 1 a 1999)",
    "Default Tobacco Beetle Critical Control Limit of the Zone": "Límite predeterminado de control crítico de escarabajos del tabaco de la zona",
    "Please Enter Tobacco Beetle Critical Control Limit": "Introduzca el límite de control crítico de escarabajos del tabaco",
    "Default tobacco beetle detections limit between 0 to 1999": "Límite predeterminado de detecciones de escarabajos del tabaco entre 0 y 1999",
    "tobacco beetle": "Escarabajo del tabaco",
    "cigarette beetle": "Escarabajo del tabaco",
    "max cigarette beetle detections allowed on the glueboard between 1 to 1999": "Detecciones máx. de escarabajos del tabaco permitidas en la placa adhesiva entre 1 y 1999",
    "max cigarette beetle detections allowed on the glueboard": "Detecciones máx. de escarabajos del tabaco permitidas en la placa adhesiva",
    "cigarette beetle sensor": "Sensor de escarabajos del tabaco",
    "Cigarette Beetle Detection": "Detección del escarabajos del tabaco",
    "cigarette beetles": "Escarabajos del tabaco",
    "Tobacco Beetle Detections": "Detecciones de escarabajos del tabaco",
    "Tobacco Beetle Trend": "Tendencia de escarabajo del tabaco",
    "Cigarette Beetle Critical Control Limit": "Límite de control crítico de escarabajos del tabaco",
    "Tobacco Beetle Glueboard Expiry": "Caducidad de la placa adhesiva de escarabajos del tabaco",
    "Tobacco Beetle Glueboard Detections": "Detecciones de la placa adhesiva de escarabajos del tabaco",
    "Zone Cigarette Beetle Critical Control Limit": "Límite de control crítico de escarabajos del tabaco",
    "missing mandatory zoneName field": "Falta el nombre de zona obligatorio",
    "Tobacco Beetle Glueboard Expiry Changed from": "La caducidad de la placa adhesiva de escarabajos del tabaco ha cambiado de",
    "Tobacco Beetle Glueboard Max Detections Changed from": "Las detecciones máx. de la placa adhesiva de escarabajos del tabaco han cambiado de",
    "zone cigarette beetle critical control limit changed from": "El límite de control crítico de escarabajos del tabaco de la zona ha cambiado de",
    "cigarette beetle sensors": "Sensores de escarabajos del tabaco",
    "tobacco beetle glueboard max detections breached": "Detecciones máx. de la placa adhesiva de escarabajos del tabaco superadas",
    "tobacco beetle glueboard expiry breached": "Caducidad de la placa adhesiva de escarabajos del tabaco superada",
    "tobacco beetle glueboard expiry changed": "La caducidad del tablero del pegamento del escarabajo del tabaco cambió",
    "tobacco beetle glueboard max detections changed": "Detecciones máx. de la placa adhesiva de escarabajos del tabaco superadas",
    "tobacco beetle glueboard replaced": "Placa adhesiva de escarabajos del tabaco sustituida",
    "all cigarette beetle sensors": "Todos los sensores de escarabajos del tabaco",
    "Tobacco Beetle Cumulative Sum": "Suma acumulativa de escarabajos del tabaco",
    "detection count": "Recuento de detecciones",
    "New Tobacco Beetle Detections at Site": "Nuevas detecciones de escarabajos del tabaco en el sitio",
    "New Tobacco Beetle Detections": "Nuevas detecciones de escarabajos del tabaco",
    "zone tobacco beetle critical control limit edited": "Límite de control crítico de escarabajos del tabaco de la zona editado",
    "zone tobacco beetle critical control limit breached": "Límite de control crítico de escarabajos del tabaco de la zona superado",
    "grouped cigarette beetle detections by site": "Detecciones de escarabajos del tabaco agrupados por sitio",
    "max. detections limit on the glueboard": "Límite de detecciones máx. en la placa adhesiva",
    "please enter max. detections limit on the glueboard": "Introduzca límite de detecciones máx. en la placa adhesiva",
    "max. detections limit allowed on the glueboard between 1 to 1999": "Límite de detecciones máx. permitidas en la placa adhesiva entre 1 y 1999",
    "enter a value between 0 to 1999": "Introduzca un valor entre 0 y 1999",
    "New Crawling Insect Detections": "Nuevas detecciones de insectos rastreros",
    "crawling insect sensors": "Sensores de insectos rastreros",
    "Crawling Insect Detections": "Detecciones de insectos rastreros",
    "Crawling Insect Detection": "Detección de insectos rastreros",
    "Crawling Insect Trend": "Tendencia de insectos rastreros",
    "Crawling Insect Activity": "Actividad de insectos rastreros",
    "crawling insect sensor": "Sensor de insectos rastreros",
    "Pest activity trend is not computed for the replaced sensor(s).": "La tendencia de actividad de plagas no se calcula para el sensor o sensores sustituidos.",
    "Pest activity trend is displayed for all the sites available.": "Se muestra la tendencia de actividad de plagas de todos los sitios disponibles.",
    "Pest activity trend is displayed for all the sites available for the customers.": "Se muestra la tendencia de actividad de plagas de todos los sitios disponibles de los clientes.",
    "Search by Sensor ID": "Buscar por ID de sensor",
    "Search by Sensor Name": "Buscar por nombre de sensor",
    "No zone map found! Please upload the zone map to map the sensors on the map.": "¡No se ha encontrado ningún mapa de zona! Cargue el mapa de zona para asignar los sensores en el mapa.",
    "max length 50": "Longitud máx. 50",
    "max length 100": "Longitud máx. 100",
    "max length 30": "Longitud máx. 30",
    "max length 16": "Longitud máx. 16",
    "Default No. of days for Flying Insect Glue Board Expiry": "N.º de días predeterminado hasta la caducidad de la placa adhesiva de insectos voladores",
    "Default max. Flying Insect detections allowed on the glue board": "Detecciones máx. predeterminadas de insectos voladores permitidas en la placa adhesiva",
    "Flying Insect Glue Board Expiring Notification (days)": "Notificación de la caducidad de la placa adhesiva de insectos voladores (días)",
    "Default No. of days for Tobacco Beetle Glue Board Expiry": "N.º de días predeterminado hasta la caducidad de la placa adhesiva de escarabajos del tabaco",
    "Default max. Tobacco Beetle detections allowed on the glue board": "Detecciones máx. predeterminadas de escarabajos del tabaco permitidas en la placa adhesiva",
    "Tobacco Beetle Glue Board Expiring Notification (days)": "Notificación de la caducidad de la placa adhesiva de escarabajos del tabaco (días)",
    "The number of days before a glue board expires and a breach is triggered.": "El número de días antes de que caduque una placa adhesiva y se active la infracción",
    "The maximum detections allowed on a glue board before a breach is triggered for the sensor.": "Las detecciones máximas permitidas en una placa adhesiva antes de que se active una infracción para el sensor.",
    "This setting defines the early notification generation - 'X' number of days before the expiry of the glue board.": "Esta configuración define la generación de notificaciones anticipadas: 'X' es el número de días hasta la caducidad de la placa adhesiva.",
    "The maximum rodent detection limit for a zone before a breach is triggered.": "El límite máximo de detección de roedores para una zona antes de que se active una infracción.",
    "The maximum crawling insect detection limit for a zone before a breach is triggered.": "El límite máximo de detección de insectos rastreros para una zona antes de que se active una infracción.",
    "The maximum flying insect detection limit for a zone before a breach is triggered.": "El límite máximo de detección de moscas para una zona antes de que se active una infracción.",
    "The maximum tobacco beetle detection limit for a zone before a breach is triggered.": "El límite máximo de detección de escarabajos del tabaco para una zona antes de que se active una infracción.",
    "value": "Valor",
    "edit setting value": "Editar valor de configuración",
    "Deleting the zone map will reset any existing sensor's mapping on the zone map. Are you sure?": "Eliminar el mapa de zona restablecerá la asignación de cualquier sensor existente en el mapa de zona. ¿Está seguro?",
    "mapped sensors": "Sensores asignados",
    "unmapped sensors": "Sensores no asignados",
    "save": "Guardar",
    "Click & drag each sensor onto the zone floor plan to assign it to the desired location.": "Haga clic y arrastre cada sensor al plano de planta de la zona para asignarlo a la ubicación deseada.",
    "Error occurred during mapping the sensor. Please try again.": "Error ocurrido al asignar el sensor. Inténtelo de nuevo.",
    "updated successfully!": "¡Actualizado correctamente!",
    "view zone map": "Ver mapa de zonas",
    "upload zone map": "Cargar mapa de zona",
    "please enter No. of days for the glueboard expiry notification": "Introduzca el n.º de días hasta la notificación de la caducidad de la placa adhesiva",
    "Default No. of days for the glueboard expiry notification between 1 to 90": "NN.º de días predeterminado hasta la notificación de la caducidad de la placa adhesiva entre 1 y 90",
    "please enter No. of days for the tobacco beetle glueboard expiry notification": "Introduzca el n.º de días hasta la notificación de la caducidad de la placa adhesiva de escarabajos del tabaco",
    "Default No. of days for the tobacco beetle glueboard expiry notification between 1 to 90": "N.º de días predeterminado hasta la notificación de la caducidad de la placa adhesiva de escarabajos del tabaco (de 1 a 90)",
    "last seen / alerts": "Visto por última vez / Alertas",
    "security": "Seguridad",
    "This setting specifies the number of days for a password to get expired for a user after the user logs into the application for the first time or when it is last changed.": "Esta configuración especifica el número de días hasta que una contraseña caduque.",
    "This setting determines if the user password should expire or not.": "Esta configuración determina si la contraseña del usuario debe caducar o no.",
    "Password expiry (days)": "Caducidad de la contraseña (días)",
    "Enable password expiry": "Activar caducidad de la contraseña",
    "please enter password expiry (days)": "Introduzca la caducidad de la contraseña (días)",
    "password expiry (days) Min value: 90  to Max: 730": "Caducidad de la contraseña (días) Valor mínimo: 90 a máximo: 730",
    "please change your password to continue.": "Cambie su contraseña para continuar.",
    "already added to the service team": "Ya ha añadido al equipo de servicio",
    "undo revoke": "Deshacer revocación",
    "This setting defines the early notification generation - \"X\" number of days before the expiry of the glue board.": "Esta configuración define la generación de notificaciones anticipadas: \"X\" es el número de días hasta la caducidad de la placa adhesiva.",
    "enabled": "Activado",
    "disabled": "Desactivado",
    "Enable / Disable password expiry": "Activar/Desactivar la caducidad de la contraseña",
    "Please your enter username": "Introduzca su nombre de usuario",
    "When enabled, this setting specifies the number of days for passwords to get expired since it was created or changed. When the password expiry is enabled (if disabled before), it is reset on the next successful login for all users.": "Cuando está activada, esta configuración especifica cuánto tiempo es válida una contraseña desde que se ha creado o cambiado. Cuando se activa la caducidad de la contraseña (si se ha desactivado anteriormente), se restablece en el siguiente inicio de sesión correcto para todos los usuarios.",
    "No unmapped sensors exist!": "¡No existen sensores no asignados!",
    "Your password has expired! Please change your password to continue.": "¡Su contraseña ha caducado! Cambie su contraseña para continuar.",
    "tobacco beetle glueboard expiring": "Placa adhesiva de escarabajos del tabaco a punto de caduca",
    "flying insect glueboard expiring": "Placa adhesiva de insectos voladores a punto de caduca",
    "Fly Glue Board expiring in": "La placa adhesiva de insectos voladores caduca en",
    "Cigarette Beetle Glue Board expiring in": "La placa adhesiva de escarabajos del tabaco caduca en",
    "Customer cannot be deleted, as it is associated with one or more sites.": "El cliente no se puede eliminar porque está asociado a uno o más sitios. ",
    "zone rodent critical control limit breached": "​Límite de control crítico de roedores de la zona superado",
    "update site": "Actualizar sitio",
    "do you want to remove": "¿Desea eliminar",
    "Rodent": "Roedor",
    "Flying Insect": "Insecto volador",
    "Tobacco Beetle": "Escarabajo del tabaco",
    "no records found" : "No se han encontrado registros",
    "Your password has been changed successfully! Please login with your new password.": "¡Su contraseña se ha cambiado correctamente! Inicie sesión con su nueva contraseña.",
    "Invalid username or password. Please enter valid credentials and try again!": "Usuario o contraseña no validos. ¡Introduzca credenciales válidas e inténtelo de nuevo!",
    "Inactive user!": "Usuario inactiva!",
    "The new password cannot be the same as the current password.": "La nueva contraseña no puede ser la misma que la contraseña actual.",
    "The current password entered is incorrect!": "¡La contraseña actual introducida es incorrecta!",
    "sensors info" : "Información de sensores",
    "breached" : "Infringido",
    "help": "Ayuda",
    "Internet of Pests Help Center": "Centro de ayuda Internet of Pests",
    "Go to IoP Dashboard": "Ir al panel de control de IoP",
    "sensors list": "Lista de sensores",
    "The Comm. Status Column": "Columna Estado de com.",
    "All sensors communicate with the IoP® platform whenever it detects “pests or in each heartbeat”. The heartbeat time for rodent and crawling sensors is 24 hrs. and for fly and cigarette beetle sensors is 30 mins.": "Todos los sensores se comunican con la plataforma IoP® cada vez que detectan “plagas o en cada latido”. El tiempo de latido de los sensores de roedores e insectos rastreros es de 24 horas y el de los sensores de escarabajos del tabaco e insectos voladores es de 30 minutos.",
    "The Comm. Status or Communication Status lets you know what is the current status of a sensor and whether it’s functional. There are 4 possible values for comm. status:": "El Estado de com. o estado de comunicación le permite saber cuál es el estado actual de un sensor y si funciona. Hay 4 valores posibles para el Estado de com.:",
    "Displays when a sensor is newly added to your account and has not yet been switched “On”.": "Muestra cuando un sensor se acaba de añadir a su cuenta y todavía no se ha encendido.",
    "Displays when a sensor is online and has detected any pest or has sent a “heartbeat” message in the last 25 hours (for Rodent and Crawling Insect sensors) or in the last 40 minutes (for Fly and Cigarette Beetle sensors)": "Muestra cuando un sensor está en línea y ha detectado alguna plaga o ha enviado un mensaje de “latido” en las últimas 25 horas (para sensores de roedores e insectos rastreros) o en los últimos 40 minutos (para sensores de insectos voladores y escarabajos del tabaco)",
    "Displays when a sensor has not detected any pest or has not sent a “heartbeat” message between last 25 hours and 48 hours (for Rodent and Crawling Insect sensors) or between last 40 minutes and 60 minutes (for Fly and Cigarette Beetle sensors)": "Muestra cuando un sensor no ha detectado ninguna plaga o no ha enviado ningún mensaje de “latido” entre las últimas 25 y 48 horas (para sensores de roedores e insectos rastros) o entre los últimos 40 y 60 minutos (para sensores de insectos voladores y escarabajos del tabaco)",
    "Displays when a sensor has not detected any pest or has not sent a “heartbeat” message for more than 48 hours (for Rodent and Crawling Insect sensors) or for more than 60 minutes (for Fly and Cigarette Beetle sensors)": "Aparece cuando un sensor no ha detectado ninguna plaga o NO ha enviado un mensaje de “latido” durante más de 48 horas (para sensores de roedores e insectos que se arrastran) o durante más de 60 minutos (para sensores de moscas y escarabajos cigarrillos)",
    "The Last Seen/Alerts Column": "Columna Visto por última vez/Alertas",
    "The Last Seen/Alerts column lets you know the last time and date an update (communication) was received from the sensor. It also includes three types of alert icons.": "La columna Visto por última vez/Alertas le permite saber la última hora y fecha en que se recibió una actualización (comunicación) del sensor. También incluye tres tipos de iconos de alerta.",
    "The New Detections Column": "Columna Nuevas detecciones",
    "The new detections column displays the number of “triggers” or pests the sensor has detected since the last time they were resolved. These are also displayed on the Detections List screen from where the Pest Management Personnel can resolve them.": "La columna Nuevas detecciones muestra la cantidad de “activaciones” o plagas que el sensor ha detectado desde la última vez que se resolvieron. Estas también se muestran en la pantalla Lista de detecciones desde donde el técnico de campo puede resolverlos.",
    "How often does the data on the sensor’s list page refresh?": "¿Con qué frecuencia se actualizan los datos de la página de lista de sensores?",
    "By default, the page will refresh automatically when the sensor detects pests or changes it's communication status.": "De forma predeterminada, la página se actualizará automáticamente cuando el sensor detecte plagas o cambie su estado de comunicación.",
    "sensors list help": "Ayuda de lista de sensores",
    "Sensor Communication Channel": "Canal de comunicación de sensores",
    "LPWAN or Wi-Fi - if a sensor is communicating over an LPWAN network or is configured to communicate over a local Wi-Fi network.": "LPWAN o Wi-Fi: si un sensor se comunica a través de una red LPWAN o está configurado para comunicarse a través de una red Wi-Fi local.",
    "Critical Battery Status": "Estado crítico de la batería",
    "Breach Status": "Estado de infracción",
    "when a zone with the specific pest type has exceeded (breached) the detections or Critical Control Limit for a specific pest.": "Cuando una zona con el tipo de plaga específico ha superado (infringido) las detecciones o el límite de control crítico de esa plaga.",
    "when a Fly or Cigarette Beetle sensor's glue board has expired. The glue board expiry is calculated from the date the sensor was added to the platform.": "Cuando la placa adhesiva de un sensor de insectos voladores o escarabajos del tabaco ha caducado. La caducidad de la placa adhesiva se calcula a partir de la fecha en la que se ha añadido el sensor a la plataforma.",
    "when a Fly or Cigarette Beetle sensor's max detections limit on the glue board has exceeded.": "Cuando se ha superado el límite de detecciones máx. de un sensor de insectos voladores o escarabajos del tabaco en la placa adhesiva.",
    "Rodent and Crawling Insect sensors contains a lithium-ion non rechargeable cell that is permanently pre-installed for the 7 years or 12,000 transmissions expected operational lifetime.": "Los sensores de roedores e insectos rastreros contienen una pila no recargable de iones de litio que está preinstalada permanentemente para una vida útil de 7 años o 12 000 transmisiones.",
    "A critical battery status is displayed when a sensor's battery is below 10%. This percentage value is calculated based on the total number of “triggers” generated by the sensor; i.e. (12,000 - Total Triggers) %.": "Se muestra un estado crítico de la batería cuando la batería de un sensor está por debajo del 10 %. Este valor porcentual se calcula en base al número total de “activaciones” generadas por el sensor; es decir, (12 000 - Activadores totales) %.",
    "The total triggers is maintained from the time the sensor was first turned on.": "El número total de activaciones se mantiene desde el momento en que se enciende el sensor por primera vez.",
    "7 Days" : "7 días",
    "30 Days" : "30 dias",
    "no matches": "No hay coincidencias",
    "valid format JPG/JPEG/PNG": "Formato válido JPG/JPEG/PNG",
    "max file size: 5MB": "Tamaño máximo de archivo: 5 MB",
    "Filter data by selecting the legends": "Filtrar Datos seleccionando las leyendas",
    "day vs day": "dia frente a dia",
    "week vs week": "semana frente a semana",
    "month vs month": "mes frente a mes",
    "year vs year": "año frente a año",
    "Aggregated for": "Añadido para",
    "Filters": "Filtros",
    "zone map successfully updated.": "Mapa de zonas actualizado correctamente.",
    "English" : "English",
    "Japanese" : "日本",
    "Spanish" : "Español",
    "submit" : "Enviar",
    "Did you forgot your password?" : "¿Ha olvidado su contraseña?",
    "Submit your Username or registered Email ID and you will receive a temporary password to login." : "Envíe su nombre de usuario o ID de correo electrónico registrado y recibirá una contraseña temporal para iniciar sesión.",
    "Back to Sign in" : "Volver a inicio de sesión",
    "Temporary password has been sent to your email successfully." : "La contraseña temporal se ha enviado a su correo electrónico correctamente.",
    "Invalid username/email id" : "Nombre de usuario/ID de correo electrónico no válido",
    "Username / Email ID" : "Nombre de usuario / ID de correo electrónico",
    "please enter your username / email id": "Introduzca su nombre de usuario / ID de correo electrónico",
    "site pest detections report" : "Informe de detecciones de plagas del sitio",
    "No unMapped sensor" : "Ningún sensor no asignado",
    "Hourly cumulative detections" : "Detecciones acumuladas por horas",
    "Percentage detections" : "Detecciones porcentuales",
    "Sensor name" : "Nombre del sensor",
    "count" : "Recuento",
    "total detections" : "Total de detecciones",
    "Please select the customer, site, date range and time zone to generate the report." : "Seleccione el cliente, el sitio, el rango de fechas y la zona horaria para generar el informe.",
    "system error!" : "¡Error del sistema!",
    "download" : "Descargar",
    "site address" : "Dirección del sitio",
    "site timezone" : "Zona horaria del sitio",
    "Pest Management Company" : "Empresa de control de plagas",
    "Evoscien Main Office" : "Sede de Evoscien",
    "generated on" : "descargado el",
    "generated by" : "descargado por",
    "Date range" : "Rango de fechas",
    "Time zone" : "Zona horaria",
    "Powered by" : "Alimentado por",
    "This report was generated" : "Este informe se ha generado",
    "Percentage change" : "Cambio porcentual",
    "generate report" : "Generar informe",
    "stakeholder performance analysis" : "Análisis del desempeño de las partes interesadas",
    "Sort by Site" : "Ordenar por sitio",
    "Sort by Zone" : "Ordenar por zona",
    "Week I: Starting Date Of" : "Semana I: Fecha de inicio de",
    "Week II: Starting Date Of" : "Semana II: Fecha de inicio de",
    "Please select the customer, pest and comparison type and time zone to generate the report." : "Seleccione el cliente, la plaga y el tipo de comparación y la zona horaria para generar el informe.",
    "deleted" : "Eliminado",
    "original day" : "Día inicial",
    "final day" : "Día final",
    "Original Day should be less than Final Day" : "El día inicial debe ser anterior al día final.",
    "Final Day should be greater than Original Day" : "El día final debe ser posterior al día inicial",
    "original week" : "Semana inicial",
    "final week" : "Semana final",
    "Original Week should be less than Final Week" : "La semana inicial debería ser anterior a la semana final",
    "Final Week should be greater than Original Week" : "La semana inicial debería ser anterior a la semana final",
    "original month" : "Mes inicial",
    "final month" : "último mes",
    "Original Month should be less than Final Month" : "El mes inicial debe ser menor que el mes final",
    "Final Month should be greater than Original Month": "El mes final debe ser posterior al mes inicial",
    "original year" : "Año original",
    "final year" : "Año final",
    "Original Year should be less than Final Year" : "El año inicial debe ser anterior al año final",
    "Final Year should be greater than Original Year" : "El año final debe ser posterior al año inicial.",
    "Please select the customer and pest to generate the report." : "Seleccione el cliente y la plaga para generar el informe.",
    "progress" : "Progreso",
    "success" : "Correcto",
    "failed" : "fallido",
    "retry" : "Reintentar",
    "status" : "Estado",
    "percentage detections" : "Detecciones porcentuales",
    "faqs" : "Preguntas frecuentes",
    "What is remote pest monitoring? Why is it important?" : "¿Qué es el monitoreo remoto de plagas? ¿Por qué es importante?",
    "Remote pest monitoring technology allows pest management professionals to track and monitor pest activity from anywhere in real-time via mobile or web-based platforms. It allows pest control technicians to quickly identify and treat hot spots on the premises, potentially reducing labour time and chemical use." : "La tecnología de monitoreo remoto de plagas permite a los profesionales del control de plagas rastrear y monitorear la actividad de las plagas desde cualquier lugar en tiempo real a través de plataformas móviles o basadas en la web. Permite a los técnicos de control de plagas identificar y tratar rápidamente los puntos calientes en las instalaciones, lo que potencialmente reduce el tiempo de mano de obra y el uso de productos químicos.",
    "What is the point of using sensors without a trap?" : "¿Cuál es la ventaja de utilizar sensores sin una trampa?",
    "The sensor’s flexible form factor allows it to be placed in usually inaccessible areas, such as roof spaces and ventilation shafts, where they can provide early warning of potential pest breeding sites and threats to infrastructure." : "El factor de forma flexible del sensor permite colocarlo en áreas normalmente inaccesibles como espacios en techos y conductos de ventilación en las que pueden alertar anticipadamente de posibles lugares de reproducción de plagas y amenazas a la infraestructura.",
    "Can a single sensor detect rodents, crawling, and flying insects?" : "¿Puede un solo sensor detectar roedores, insectos rastreros y voladores?",
    "No. While the underlying technology is the same, each sensor is designed for a particular pest type." : "No. Aunque la tecnología subyacente es la misma, cada sensor está diseñado para un tipo de plaga en particular.",
    "Will my sensor battery last 7 years? Are the batteries replaceable?" : "¿La batería de mi sensor durará 7 años? ¿Se pueden sustituir las baterías?",
    "The expected battery life of the rodent and crawling pest sensors is 7 years or 12,000 transmissions. Actual battery life would vary depending on the frequency of pest activity in the area. Batteries can only be replaced by a trained technician." : "La duración prevista de la batería de los sensores de plagas de roedores e insectos rastreros es de 7 años o 12 000 transmisiones. La duración real de la batería variará según la frecuencia de la actividad de las plagas en el área. Las baterías solo pueden ser sustituidas por un técnico formado.",
    "Flying insect sensors are powered by the main power supply, and do not require batteries." : "Los sensores de insectos voladores funcionan con la fuente de alimentación principal y no requieren baterías.",
    "Do I need Wi-Fi for my IoP® setup?" : "¿Necesito Wi-Fi para la configuración de mi IoP®?",
    "A Wi-Fi or ethernet connection is only required for the local gateway to receive signals from the rodent and crawling pest sensors. Wi-Fi is preferred for flying insect sensors, but they can transmit data to rodents and crawling insect gateways." : "Solo se requiere una conexión Wi-Fi o Ethernet para que la puerta de enlace local reciba señales de los sensores de plagas de roedores e insectos rastreros. Se prefiere Wi-Fi para los sensores de insectos voladores, pero pueden transmitir datos puertas de enlace de roedores e insectos rastreros.",
    "Since data transmission is minimal, low-data pre-paid SIMs can be used if local Wi-Fi or ethernet connections are unavailable." : "Dado que la transmisión de datos es mínima, se pueden utilizar tarjetas SIM de prepago de bajo consumo de datos si las conexiones Wi-Fi o Ethernet locales no están disponibles.",
    "Can I add, relocate, or remove sensors if needed?" : "¿Puedo añadir, reubicar o eliminar sensores en caso necesario?",
    "Yes, the IoP® system allows seamless sensor addition, removal, replacement, and relocation without data loss. For more information, refer to the Technician’s Handbook." : "Sí, el sistema IoP® permite añadir, eliminar, sustituir y reubicar sensores sin pérdida de datos. Para obtener más información, consulte el Manual del técnico.",
    "What do I do if my sensors stop working?" : "¿Qué hago si mis sensores dejan de funcionar?",
    "In the absence of pest activity, sensors transmit a heartbeat every 24 hours to indicate their functionality. Contact your provider to repair or replace the sensor if no heartbeat signal is received, and the sensor status changes to idle or offline.": "En ausencia de actividad de plagas, los sensores transmiten un latido cada 24 horas para indicar su funcionamiento. Comuníquese con su proveedor para reparar o sustituir el sensor si no se recibe ninguna señal de latido y el estado del sensor cambia a inactivo o fuera de línea.",
    "How do I sign up for an IoP® account?" : "¿Cómo me registro para tener una cuenta IoP®?",
    "Your IoP® super-admin account will be generated upon confirmation of your order. Contact our support team for more information, or to request a demo." : "Su cuenta de superadministrador de IoP® se creará una vez que haya confirmado su pedido. Contacte con nuestro equipo de asistencia para obtener más información o para solicitar una demostración.",
    "I’ve received a breach alert. What do I do next?" : "He recibido una alerta de infracción. ¿Que tengo que hacer?",
    "Critical control limits refer to the acceptable level of pest activity. The values can be manually adjusted per pest according to customer requirements and/or local regulations. An alert is sent when sensor detections exceed the critical control limit. Breach alerts call for immediate action by your pest control service provider." : "Los límites de control críticos se refieren al nivel aceptable de actividad de plagas. Los valores se pueden ajustar manualmente por plaga según los requisitos del cliente y/o las normativas locales. Se envía una alerta cuando las detecciones del sensor superan el límite de control crítico. Las alertas de infracción exigen una acción inmediata por parte de su proveedor de servicios de control de plagas.",
    "What type of alerts does the IoP® platform provide?" : "¿Qué tipo de alertas proporciona la plataforma IoP®?",
    "The IoP® platform sends real-time alerts for critical control limit breaches and sensor communication status updates, including idle/offline/active, site-related alerts like site access granted, site detections resolved, and zone-related alerts when pest detections are resolved in a zone." : "La plataforma IoP® envía alertas en tiempo real para las infracciones de los límites de control críticos y actualizaciones del estado de comunicación del sensor, incluidas alertas de estado inactivo/fuera de línea/activo, relacionadas con el sitio, como acceso al sitio concedido, detecciones del sitio resueltas y alertas relacionadas con la zona cuando se resuelven las detecciones de plagas en una zona.",
    "How does IoP® help identify pest entry points?" : "¿Cómo ayuda IoP® a identificar los puntos de entrada de plagas?",
    "IoP® sensors provide valuable insight into pest movement patterns by recording which side of the sensor the pest contacted first. Taken together, this data can help PMPs to identify and target potential breeding sites and points of entry." : "Los sensores IoP® ofrecen información valiosa sobre los patrones de movimiento de las plagas al registrar con qué lado del sensor entró en contacto primero la plaga. En conjunto, estos datos pueden ayudar a los profesionales del control de plagas a identificar y abordar posibles sitios de reproducción y puntos de entrada.",
    "Does the IoP® platform provide documentation for regulatory compliance?" : "¿La plataforma IoP® proporciona documentación para el cumplimiento normativo?",
    "Yes, all pest activity is securely stored and accessible on the IoP® platform anytime. Users can generate and view historical and current pest activity reports to demonstrate regulatory compliance." : "Sí, toda la actividad de plagas se guarda de forma segura y se puede acceder a ella en la plataforma IoP® en cualquier momento. Los usuarios pueden generar y ver informes históricos y actuales de la actividad de las plagas para demostrar el cumplimiento normativo.",
    "Where can the sensors be used?" : "¿Dónde se pueden utilizar los sensores?",
    "The sensors can be deployed in areas prone to pest activity, like kitchens, storage areas, waste disposal areas, etc. They can also be used in hard-to-access areas like roof spaces and ventilation ducts." : "Los sensores se pueden instalar en áreas propensas a la actividad de plagas, como cocinas, áreas de almacenamiento, áreas de eliminación de residuos, etc. También se pueden utilizar en áreas de difícil acceso, como espacios en techos y conductos de ventilación.",
    "Is the sensor waterproof?" : "¿El sensor es resistente al agua?",
    "Yes, the sensor is IP67 rated, but we recommend turning the unit off and drying it thoroughly before reuse to reduce the possibility of false detections." : "Sí, el sensor tiene clasificación IP67, pero recomendamos apagar la unidad y secarla completamente antes de volver a utilizarla para reducir la posibilidad de detecciones falsas.",
    "If you have more queries please click below our official website" : "Si tiene más consultas, haga clic en nuestro sitio web oficial a continuación",
    "Follow us on" : "Síganos en",
    "Download Apps" : "Descargar aplicaciones",
    "User Guides" : "Guías de usuario",
    "IoP® Platform User Manual" : "Manual de usuario de la plataforma IoP®",
    "IoP® Technician’s Handbook" : "Manual del técnico de IoP®",
    "Frequently Asked Questions" : "Preguntas frecuentes",
    "Visit" : "Visite",
    "IoP® website" : "Sitio web de IoP®",
    "for more information" : "para obtener más información",
    "Sensor detected possible pest shelter or entrance point." : "El sensor ha detectado un posible refugio o punto de entrada de plagas.",
    "Sensors detected possible food source or entrance point." : "Los sensores han detectado una posible fuente de alimento o punto de entrada.",
    "Error: Unable to connect to the backend server." : "Error: No se puede conectar con el servidor backend.",
    "Sensor type not enabled: Crawling Insect" : "Tipo de sensor no activado: Insecto rastrero",
    "Sensor type not enabled: Rodent" : "Tipo de sensor no activado: Roedor",
    "Sensor type not enabled: Fly" : "Tipo de sensor no activado: Volar",
    "Sensor type not enabled: Cigarette Beetle" : "Tipo de sensor no activado: Escarabajo del tabaco",
    "Zone Events" : "Eventos de zona",
    "zone live monitoring disabled" : "Monitoreo en vivo de zona desactivado",
    "zone live monitoring enabled" : "Monitoreo en vivo de zona activado",
    "live monitoring toggle" : "Alternar monitoreo en vivo",
    "live monitoring" : "Monitoreo en vivo",
    "live monitoring disabled" : "Monitoreo en vivo desactivado",
    "live monitoring enabled" : "Monitoreo en vivo activado",
    "enable live monitoring" : "Habilitar monitoreo en vivo",
    "Enabling live monitoring will allow pest detections and alerts in real-time from being updated on the platform." : "Activar el monitoreo en vivo permitirá que las detecciones y alertas de plagas en tiempo real se actualicen en la plataforma.",
    "I confirm that I have turned ON all the sensors and they are reporting Active." : "Confirmo que he encendido todos los sensores y que su estado es activo.",
    "successfully enabled the live monitoring" : "Monitoreo en vivo activado correctamente",
    "disable live monitoring" : "Desactivar monitoreo en vivo",
    "Disabling live monitoring will ignore any pest detections that would be sent by the sensors and will prevent real-time detections and alerts from being updated on the platform." : "Desactivar el monitoreo en vivo ignorará las detecciones de plagas que enviarían los sensores y evitará que las detecciones y alertas en tiempo real se actualicen en la plataforma.",
    "I confirm to disable live monitoring for this zone." : "Confirmo que deseo desactivar el monitoreo en vivo para esta zona.",
    "Daily heartbeat and power ON messages will NOT be ignored and the application will continue to update the Communication Status and Last Seen Timestamp for all the sensors in this zone." : "Los mensajes diarios de latido y encendido NO se ignorarán y la aplicación continuará actualizando el Estado de comunicación y la Fecha y hora de Visto por última vez de todos los sensores en esta zona.",
    "successfully disabled the live monitoring" : "Monitoreo en vivo desactivado correctamente",
    "sensor event rejected due to disabled zone live monitoring" : "Evento de sensor rechazado porque el monitoreo en vivo de la zona está desactivado",
    "Warning! Zone Live monitoring is currently disabled. Last sensor detection was" : "¡Advertencia! El monitoreo en vivo de la zona está actualmente desactivado. La última detección del sensor ha sido",
    "Enable Live Monitoring for the zone to start logging  sensor detections." : "Active el monitoreo en vivo para que la zona comience a registrar detecciones de sensor.",
    "The following sensors are not Active. Please cycle the sensor power and ensure that they are reporting Active in the application before you Enable Live Monitoring for this zone." : "Los siguientes sensores no están activos. Vuelva a encender el sensor y asegúrese de que su estado sea activo en la aplicación antes de activar el monitoreo en vivo para esta zona.",
    "I acknowledge the above issues and still want to enable live monitoring." : "Reconozco los problemas anteriores y todavía deseo habilitar el monitoreo en vivo.",
    "The following Active sensors were last seen before Live Monitoring for this Zone was Disabled on" : "Los siguientes sensores activos se han visto por última vez antes de que se desactivara el monitoreo en vivo para esta zona el",
    ". If you may have switched OFF the following sensors, then please switch them back ON and ensure that they are reporting Active in the application before you Enable Live Monitoring for this zone." : ". Si ha apagado los siguientes sensores, vuelva a encenderlos y asegúrese de que su estado sea activo en la aplicación antes de activar el monitoreo en vivo para esta zona.",
    "remove floor map" : "Eliminar mapa de planta",
    "Live Monitoring" : "El monitoreo en vivo",
    "for the zone where you are adding the sensor is" : "de la zona donde está añadiendo el sensor está",
    "Disabled" : "Desactivado",
    "Please" : "Por favor",
    "Enable" : "actívelo",
    "after you have added and turned ON all the sensors in this zone to monitoring pest detections" : "después de haber añadido y encendido todos los sensores en esta zona para monitorear las detecciones de plagas",
    "Remove Zone Map" : "Eliminar mapa de zona",
    "Live Monitoring for this zone is" : "El monitoreo en vivo para esta zona está",
    "disabled." : "desactivado.",
    "After you have added and turned ON all the sensors in this zone;" : "Después de haber añadido y encendido todos los sensores en esta zona;",
    "enable " : "activar ",
    "Live Monitoring to monitor pest detections." : "Monitoreo en Vivo para monitorear las detecciones de plagas.",
    "Disabled Live Monitoring Zones" : "Zonas de monitoreo en vivo desactivadas",
    "Zone Map Removed Successfully" : "Mapa de zona eliminado correctamente",
    "Upload zone map" : "Cargar mapa de zona",
    "No zone map found!" : "¡No se ha encontrado ningún mapa de zona!",
    "last updated timestamp" : "Fecha y hora de la última actualización",
    "Zone Map Uploaded Successfully" : "Mapa de zona cargado correctamente",
    "all roles" : "Todos los roles",
    "no detections reported!" : "¡No se han notificado detecciones!",
    "Removing the zone map will un-map all the currently mapped sensor and delete the zone map. Are you sure you want to remove the zone map?" : "Eliminar el mapa de zona cancelará la asignación de todos los sensores actualmente asignados y eliminará el mapa de zona. ¿Seguro que desea eliminar el mapa de zona?",
    "Successfully added sensor(s)." : "Sensor o sensores añadidos correctamente.",
    "Click here" : "Haga clic aquí",
    "to map these sensors on the zone map." : "para asignar estos sensores en el mapa de zona.",
    "is currently disabled" : "Actualmente está deshabilitado",
    "for the" : "Para el",
    "click here " : "haga clic aquí ",
    "live monitoring for this zone only after turning" : "monitoreo en vivo para esta zona solo después de",
    "zone to prevent any false detections during handling of the sensors." : "zona para evitar falsas detecciones durante la manipulación de los sensores.",
    "ON" : "encender",
    "the newly added sensors." : "los sensores recién añadidos.",
    "is not mapped on the zone map" : "no está asignado en el mapa de zona",
    "latest sensor image" : "última imagen del sensor",
    "Username must be 5-20 characters long and must not include any spaces." : "El nombre de usuario debe tener entre 5 y 20 caracteres y no debe incluir espacios.",
    "please enter phone number" : "Introduzca el número de teléfono",
    "flying insect glueboard replaced" : "Reemplazo del tablero adhesivo para insectos voladores",
    "First Name must be between 2 and 50 chars." : "El nombre debe tener entre 2 y 50 caracteres.",
    "Last Name must be between 2 and 50 chars." : "El apellido debe tener entre 2 y 50 caracteres.",
    "Customer Name must be between 2 and 50 chars." : "El nombre del cliente debe tener entre 2 y 50 caracteres.",
    "Street address  must be between 5 and 250 chars." : "La dirección postal debe tener entre 5 y 250 caracteres.",
    "City must be between 2 and 50 chars." : "La ciudad debe tener entre 2 y 50 caracteres.",
    "State must be between 2 and 50 chars." : "El estado debe tener entre 2 y 50 caracteres.",
    "Country must be between 2 and 50 chars." : "El país debe tener entre 2 y 50 caracteres.",
    "Postal code must be between 4 and 10 chars." : "El código postal debe tener entre 4 y 10 caracteres.",
    "Name must be between 2 and 50 chars." : "El nombre debe tener entre 2 y 50 caracteres.",
    "Site Name must be between 2 and 50 chars." : "El nombre del sitio debe tener entre 2 y 50 caracteres.",
    "Zone name must be between 2 and 50 chars." : "El nombre de la zona debe tener entre 2 y 50 caracteres.",
    "Sensor ID must be between 6 and 16 chars." : "El ID del sensor debe tener entre 6 y 16 caracteres.",
    "Sensor name must be between 2 and 25 chars." : "El nombre del sensor debe tener entre 2 y 25 caracteres.",
    "Description must be between 2 and 100 chars." : "La descripción debe tener entre 2 y 100 caracteres.",
    "flying insect glueboard expiry breached" : "Caducidad de la placa adhesiva de insectos voladores superada",
    "Email ID already taken. Please enter a different one." : "El ID de correo electrónico ya existe. Introduzca uno diferente.",
    "sensor scanned" : "Sensor escaneado",
    "scanned" : "Escaneado",
    "flying insect sensor": "Sensor de insectos voladores",
    "personalization" : "Personalización",
    "setting value options" : "Configuración de opciones de valores",
    "Indicates the format that dates are displayed on the web and mobile applications." : "Indica el formato en el que se muestran las fechas en la web y las aplicaciones móviles.",
    "Indicates the format that times are displayed on the web and mobile applications." : "Indica el formato en el que se muestran los horas en la web y las aplicaciones móviles.",
    "Date Format" : "Formato de fecha",
    "Time Format" : "Formato de hora",
    "please enter a valid phone number" : "Por favor ingresa un número de teléfono válido",
    "Timestamp" : "Marca de tiempo",
    "The entry points can only be determined with 2 or more sensors." : "Los puntos de entrada sólo se pueden determinar con 2 o más sensores.",
    "edit zone" : "Editar zona",
    "add zone" : "Agregar zona",
    "Zone Created Successfully" : "Zona creada con éxito",
    "Zone Updated Successfully" : "Zona actualizada con éxito",
    "You've reached the maximum limit of 30 zones that can be created." : "Has alcanzado el límite máximo de 30 zonas que se pueden crear.",
    "no zone available" : "ninguna zona disponible",
    "to add a zone." : "para agregar una zona.",
    "Customer/Site Access Control": "Control de acceso al cliente/sitio",
    "Stakeholder Performance Report" : "Informe de desempeño de las partes interesadas",
    "Pest Infestation Summary Report" : "Informe resumido de infestación de plagas",
    "Hours Distribution Report" : "Informe de distribución de horas",
    "Time Series Report" : "Informe de serie temporal",
    "Cumulative Sum Report" : "Informe de suma acumulada",
    "Time Series Comparison Report" : "Informe de comparación de series temporales",
    "vs" : "vs",
    "Download PDF File" : "Descargar archivo PDF",
    "group by" : "Agrupar por",
    "export" : "Exportar",
    "Remaining Battery" : "Batería restante",
    "Estimated Battery Life" : "Duración estimada de la batería",
    "The Remaining battery percentage is calculated as: (Remaining transmissions / 12,000) × 100. This shows the remaining capacity of the sensor's battery based on its total usage." : "El porcentaje de batería restante se calcula como: (Transmisiones restantes / 12 000) × 100. Esto muestra la capacidad restante de la batería del sensor en función de su uso total.",
    "months" : "Meses",
    "Transmissions Overview" : "Descripción general de las transmisiones",
    "The total number of transmissions received from the sensor since it was first added to the account." : "El número total de transmisiones recibidas del sensor desde que se agregó por primera vez a la cuenta.",
    "Remaining transmissions are calculated by subtracting the total transmissions received from the sensor from the maximum allowable limit of 12,000." : "Las transmisiones restantes se calculan restando el total de transmisiones recibidas del sensor del límite máximo permitido de 12,000.",
    "The daily rate is calculated by dividing the total number of transmissions received from the sensor over the last 3 months by 90 days." : "La tarifa diaria se calcula dividiendo el número total de transmisiones recibidas del sensor durante los últimos 3 meses por 90 días.",
    "Right" : "Bien",
    "Left" : "Izquierda",
    "Center" : "Centro",
    "Received" : "Recibido",
    "Daily Rate" : "Tarifa Diaria",
    "transmission/day" : "transmisión/día",
    "transmissions/day" : "transmisiones/día",
    "End of life, replace immediately!" : "¡Fin de vida útil, reemplace inmediatamente!",
    "This sensor cannot be added as it has been previously used and its battery is critically low. Please replace the battery or use a new sensor." : "Este sensor no se puede agregar ya que se ha utilizado anteriormente y su batería está críticamente baja. Reemplace la batería o use un sensor nuevo.",
    "TRANSMISSION_TOOLTIP_WITH_RATE": "La duración estimada de la batería se calcula utilizando la tasa de transmisión diaria de {{dailyRate}} transmisiones/día, derivada del total de transmisiones durante los últimos 90 días dividido por 90.",
    "TRANSMISSION_TOOLTIP_NO_RATE": "La duración estimada de la batería se calcula utilizando la tasa de transmisión diaria, derivada del total de transmisiones durante los últimos 90 días dividido por 90.",
    "Sensor name can only include numbers, spaces, periods, underscores, colons, hyphens, and parentheses. It cannot start or end with a space." : "El nombre del sensor solo puede incluir números, espacios, puntos, guiones bajos, dos puntos, guiones y paréntesis. No puede comenzar ni terminar con un espacio.",
    "sensor validation tests" : "Pruebas de validación de sensores",
    "Test Number" : "Número de prueba",
    "Deployment" : "Despliegue",
    "Test Frequency" : "Frecuencia de prueba",
    "Test Date" : "Fecha de prueba",
    "Detection Count" : "Recuento de detección",
    "Video Time Window" : "Ventana de tiempo de vídeo",
    "Video URL" : "URL del vídeo",
    "Please select the test to generate the report." : "Seleccione la prueba para generar el informe.",
    "test" : "Prueba",
    "all test" : "Todas las pruebas",
    "Please enter passwords to protect the PDF" : "Por favor ingrese contraseñas para proteger el PDF",
    "Please rotate your device to landscape view for the best experience." : "Gire su dispositivo a la vista horizontal para obtener la mejor experiencia.",
    "all status" : "Todo el estado",
    "inactive" : "Inactivo",
    "Are you sure you want to activate this user" : "¿Estás seguro de que deseas activar este usuario?",
    "Are you sure you want to inactivate this user" : "¿Estás seguro de que quieres inactivar a este usuario?",
    "inactivate user" : "Desactivar usuario",
    "Select Option": "Seleccionar opción",
    "Update Reporting Manager" : "Actualizar administrador de informes",
    "The selected user has direct reportees. To proceed with disabling this user, please choose one of the following options:": "El usuario seleccionado tiene subordinados directos. Para continuar con la desactivación de este usuario, elija una de las siguientes opciones:",
    "Reassign Reportees" : "Reasignar reportados",
    "Proceed Without Reassignment" : "Continuar sin reasignación",
    "Select a new reporting manager for the affected users before disabling the current user." : "Seleccione un nuevo administrador de informes para los usuarios afectados antes de deshabilitar al usuario actual.",
    "Disable this user without reassigning the direct reportees to a new reporting manager." : "Deshabilite este usuario sin reasignar a los subordinados directos a un nuevo administrador de informes.",
    "Reportees of" : "Reporteros de",
    "Select New Reporting Manager" : "Seleccionar nuevo administrador de informes",
    "I confirm to disable the current user and reassign all reportees to the selected reporting manager." : "Confirmo deshabilitar al usuario actual y reasignar a todos los reportados al administrador de informes seleccionado.",
    "I confirm to inactivate this user without changing the reporting manager of the above users." : "Confirmo desactivar este usuario sin cambiar el administrador de informes de los usuarios anteriores.",
    "No eligible reporting managers available." : "No hay administradores de informes elegibles disponibles.",
    "There are no users with an equivalent role who can be assigned as the reporting manager. You can either create a new user with the same role to reassign the reportees or proceed to deactivate this user without reassignment." : "No hay usuarios con una función equivalente a quienes se les pueda asignar como administrador de informes. Puede crear un nuevo usuario con el mismo rol para reasignar a los denunciados o proceder a desactivar este usuario sin reasignación.",
    "Reporting Manager Updated Successfully." : "Gerente de informes actualizado con éxito.",
    "Successfully inactivated this user." : "Desactivé exitosamente a este usuario.",
    "Successfully activated this user." : "Activado con éxito este usuario.",
    "please select the customer." : "Por favor seleccione el cliente.",
    "First Detection Direction" : "Primera dirección de detección",

    "list" : "Lista",
    "report" : "Informe",
    "test execution list" : "Lista de ejecución de pruebas",
    "all objective" : "All Objective",
    "objective" : "Objetiva",
    "species" : "Especies",
    "sensor firmware version" : "Versión del firmware del sensor",
    "test execution number" : "Número de ejecución de prueba",
    "create test execution" : "Crear ejecución de prueba",
    "total test runs" : "Ejecuciones de prueba totales",
    "please enter total test runs" :  "Por favor, introduzca el número total de ejecuciones de pruebas",
    "min value 1 & max value 100" : "Valor mínimo 1 y valor máximo 100",
    "min value 1 & max value 50" : "Valor mínimo 1 y valor máximo 50",
    "test description" : "Descripción de la prueba",
    "temperature" : "Temperatura",
    "please enter temperature" : "Por favor ingrese la temperatura",
    "humidity" : "Humedad",
    "please enter humidity" : "Por favor ingrese la humedad",
    "remarks" : "Observaciones",
    "start" : "Comenzar",
    "end" : "Fin",
    "create & start" : "Crear y comenzar",
    "Test execution successfully created." : "La ejecución de la prueba se creó correctamente.",
    "Test execution successfully created and started." : "La ejecución de la prueba se creó e inició exitosamente.",
    "Test execution has been successfully updated." : "La ejecución de la prueba se ha actualizado correctamente.",
    "Created Date" : "Fecha de creación",
    "firmware version" : "Versión de firmware",
    "Test execution started successfully." : "La ejecución de la prueba comenzó exitosamente.",
    "Test execution ended successfully." : "La ejecución de la prueba finalizó con éxito.",
    "Test execution deleted successfully." : "La ejecución de la prueba se eliminó correctamente.",
    "do you want to delete" : "¿Quieres eliminar?",
    "Please select a customer, site, and zone to generate the sensor list." : "Seleccione un cliente, sitio y zona para generar la lista de sensores.",
    "test execution details" : "Detalles de ejecución de la prueba",
    "reset filters" : "Restablecer filtros",
    "Sensor Firmware Details" : "Detalles del firmware del sensor",
    "cumulative type" : "Tipo acumulativo",
    "date" : "Fecha",
    "comments" : "Comentarios",
    "Test Start Date" : "Fecha de inicio de la prueba",
    "Test End Date" : "Fecha de finalización de la prueba",
    "Tester" : "Ensayadora",
    "Transmission Frequency" : "Frecuencia de transmisión",
    "Firmware Version Date" : "Fecha de versión del firmware",
    "Videos" : "Vídeos",
    "Sensor Detections Summary" : "Resumen de detecciones de sensores",
    "Total Detection Count" : "Recuento total de detección",
    "do you want to start the test execution" : "¿Quieres iniciar la ejecución de la prueba ",
    "do you want to end the test execution" : "¿Quieres finalizar la ejecución de la prueba ",
    "Test Create Date Range" : "Prueba Crear rango de fechas",
    "Edit Test Execution" : "Editar ejecución de prueba",
    "YouTube Video URL" : "URL del vídeo de YouTube",
    "temperature ºC" : "Temperatura ºC",
    "humidity %" : "Humedad %",
    "min value -30 & max value 100" : "Valor mínimo -30 y valor máximo 100",
    "min value 0 & max value 100" : "Valor mínimo 0 y valor máximo 100",
    "max 500 chars." : "Máximo 500 caracteres.",
    "Test Report" : "Informe de prueba",
    // "test execution" : "Ejecución de prueba",
    "Consolidated Sensor Detections Summary" : "Resumen de detecciones de sensores consolidados",
    "Please select the sensor type to generate the report." : "Seleccione el tipo de sensor para generar el informe.",
    "no test reported!" : "¡No se informó ninguna prueba!",
    "Transmission Frequency (mins)" : "Frecuencia de transmisión (minutos)",
    "Version Date" : "Fecha de versión",
    "Updated Date" : "Fecha de actualización",
    "objective name" : "Nombre del objetivo",
    "objective code" : "Código objetivo",
    "species name" : "Nombre de la especie",
    "create objective" : "Crear objetivo",
    "please enter objective name" : "Por favor ingrese el nombre del objetivo",
    "please enter objective code" : "Por favor ingrese el código objetivo",
    "edit objective" : "Editar objetivo",
    "please enter species name" : "Por favor ingrese el nombre de la especie",
    "Test species created successfully." : "Especie de prueba creada exitosamente.",
    "Test species updated successfully." : "Especie de prueba actualizada exitosamente.",
    "Test species delete successfully." : "La especie de prueba se eliminó correctamente.",
    "Test objective created successfully." : "Objetivo de prueba creado exitosamente.",
    "Test objective updated successfully." : "Objetivo de la prueba actualizado exitosamente.",
    "Test objective delete successfully." : "El objetivo de la prueba se eliminó correctamente.",
    "firmware versions" : "Versiones de firmware",
    "Create Firmware Version" : "Crear versión de firmware",
    "Comments must be between 2 and 100 chars." : "Los comentarios deben tener entre 2 y 100 caracteres.",
    "Please enter firmware version" : "Por favor ingrese la versión del firmware",
    "Please enter transmission frequency (mins)" : "Por favor ingrese la frecuencia de transmisión (minutos)",
    "Test Firmware Version created successfully." : "Versión de firmware de prueba creada exitosamente.",
    "Test Firmware Version updated successfully." : "La versión del firmware de prueba se actualizó correctamente.",
    "Test firmware version deleted successfully." : "La versión de firmware de prueba se eliminó correctamente.",
    "Edit Firmware Version" : "Editar versión de firmware",
    "objectives" : "Objetivos",
    "firmware version details" : "Detalles de la versión del firmware"
 }